[data-frame="adm-platform-frame-login-basic"] {
    background: #F00;

    $primary-color: #00A86B;
    $secondary-color: #4A5568;
    $background-color: #E5E5E5;
    $white: #FFFFFF;
    width: 100%;
    
    .cls-frame {
        &-login-basic {
            background-color: $background-color;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
            width: 100%;
            padding: 4rem;

            &-container {
                display: flex;
                flex-direction: column; 
                max-width: 40rem;
                background-color: $white;
                border-radius: 1rem;
                overflow: hidden;
                box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
            }

            &-image {
                background: linear-gradient(to right, #6fa4e0, #0c356a);
                color: $white;
                padding: 4rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                padding: 1rem;

                &-logo {
                    font-size: 2.4rem;
                    font-weight: bold;
                }

                &-advise {
                    font-style: italic;
                }
            }

            &-content {               
                padding: 4rem;
                width: 100%;
                padding-top: 2rem;

                h2 {
                    margin-bottom: 1rem;
                    color: $secondary-color;
                }

                p {
                    color: $secondary-color;
                    margin-bottom: 2rem;
                }

                &-system {
                    &-content{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;   
                        font-weight: bold;    
                        font-size: 1.2rem;               
                    }
                }
            }
        }
    }
}