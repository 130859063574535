// ISO 3166-1 alpha2
// https://www.dadosmundiais.com/codigos-de-pais.php

span.flag {
    width: 44px;
    height: 30px;
    display: inline-block;
}

img.flag {
    width: 30px
}

.flag {
    //background: url(https://primefaces.org/cdn/primeng/images/flag/flags_responsive.png) no-repeat;
    background: url('../../../../../../../public/common/imgs/flags_responsive.png') no-repeat;
    background-size: 100%;
    vertical-align: middle;
}

.flag-ad {
    background-position: 0 .413223%
}

.flag-ae {
    background-position: 0 .826446%
}

.flag-af {
    background-position: 0 1.239669%
}

.flag-ag {
    background-position: 0 1.652893%
}

.flag-ai {
    background-position: 0 2.066116%
}

.flag-al {
    background-position: 0 2.479339%
}

.flag-am {
    background-position: 0 2.892562%
}

.flag-an { // Antilhas Neerlandesas
    background-position: 0 3.305785%
}

.flag-ao {
    background-position: 0 3.719008%
}

.flag-aq {
    background-position: 0 4.132231%
}

.flag-ar {
    background-position: 0 4.545455%
}

.flag-as {
    background-position: 0 4.958678%
}

.flag-at {
    background-position: 0 5.371901%
}

.flag-au {
    background-position: 0 5.785124%
}

.flag-aw {
    background-position: 0 6.198347%
}

.flag-az {
    background-position: 0 6.61157%
}

.flag-ba, .flag-bih {
    background-position: 0 7.024793%
}

.flag-bb, .flag-brb {
    background-position: 0 7.438017%
}

.flag-bd, .flag-bgd {
    background-position: 0 7.85124%
}

.flag-be, .flag-bel {
    background-position: 0 8.264463%
}

.flag-bf, .flag-bfa {
    background-position: 0 8.677686%
}

.flag-bg, .flag-bgr {
    background-position: 0 9.090909%
}

.flag-bh, .flag-bhr {
    background-position: 0 9.504132%
}

.flag-bi, .flag-bdi {
    background-position: 0 9.917355%
}

.flag-bj, .flag-ben {
    background-position: 0 10.330579%
}

.flag-bm, .flag-bmu {
    background-position: 0 10.743802%
}

.flag-bn, .flag-brn {
    background-position: 0 11.157025%
}

.flag-bo, .flag-bol {
    background-position: 0 11.570248%
}

.flag-br {
    background-position: 0 11.983471%
}

.flag-bs, .flag-bhs {
    background-position: 0 12.396694%
}

.flag-bt, .flag-btn {
    background-position: 0 12.809917%
}

.flag-bv, .flag-bvt {
    background-position: 0 13.22314%
}

.flag-bw, .flag-bwa {
    background-position: 0 13.636364%
}

.flag-by, .flag-blr {
    background-position: 0 14.049587%
}

.flag-bz, .flag-blz {
    background-position: 0 14.46281%
}

.flag-ca, .flag-can {
    background-position: 0 14.876033%
}

.flag-cc, .flag-cck {
    background-position: 0 15.289256%
}

.flag-cd, .flag-cod {
    background-position: 0 15.702479%
}

.flag-cf, .flag-caf {
    background-position: 0 16.115702%
}

.flag-cg, .flag-cog {
    background-position: 0 16.528926%
}

.flag-ch, .flag-che {
    background-position: 0 16.942149%
}

.flag-ci, .flag-civ {
    background-position: 0 17.355372%
}

.flag-ck, .flag-cok {
    background-position: 0 17.768595%
}

.flag-cl, .flag-chl {
    background-position: 0 18.181818%
}

.flag-cm, .flag-cmr {
    background-position: 0 18.595041%
}

.flag-cn, .flag-chn {
    background-position: 0 19.008264%
}

.flag-co, .flag-col {
    background-position: 0 19.421488%
}

.flag-cr, .flag-cri {
    background-position: 0 19.834711%
}

.flag-cu, .flag-cub {
    background-position: 0 20.247934%
}

.flag-cv, .flag-cpv {
    background-position: 0 20.661157%
}

.flag-cx, .flag-cxr {
    background-position: 0 21.07438%
}

.flag-cy, .flag-cyp {
    background-position: 0 21.487603%
}

.flag-cz, .flag-cze {
    background-position: 0 21.900826%
}

.flag-de, .flag-deu {
    background-position: 0 22.31405%
}

.flag-dj, .flag-dji {
    background-position: 0 22.727273%
}

.flag-dk, .flag-dnk {
    background-position: 0 23.140496%
}

.flag-dm, .flag-dma {
    background-position: 0 23.553719%
}

.flag-do, .flag-dom {
    background-position: 0 23.966942%
}

.flag-dz, .flag-dza {
    background-position: 0 24.380165%
}

.flag-ec, .flag-ecu {
    background-position: 0 24.793388%
}

.flag-ee, .flag-est {
    background-position: 0 25.206612%
}

.flag-eg, .flag-egy {
    background-position: 0 25.619835%
}

.flag-eh, .flag-esh {
    background-position: 0 26.033058%
}

.flag-er, .flag-eri {
    background-position: 0 26.446281%
}

.flag-es, .flag-esp {
    background-position: 0 26.859504%
}

.flag-et, .flag-eth {
    background-position: 0 27.272727%
}

.flag-fi, .flag-fin {
    background-position: 0 27.68595%
}

.flag-fj, .flag-fji {
    background-position: 0 28.099174%
}

.flag-fk, .flag-flk {
    background-position: 0 28.512397%
}

.flag-fm, .flag-fsm {
    background-position: 0 28.92562%
}

.flag-fo, .flag-fro {
    background-position: 0 29.338843%
}

.flag-fr, .flag-fra {
    background-position: 0 29.752066%
}

.flag-ga, .flag-gab {
    background-position: 0 30.165289%
}

.flag-gd, .flag-grd {
    background-position: 0 30.578512%
}

.flag-ge, .flag-geo {
    background-position: 0 30.991736%
}

.flag-gf, .flag-guf {
    background-position: 0 31.404959%
}

.flag-gh, .flag-gha {
    background-position: 0 31.818182%
}

.flag-gi, .flag-gib {
    background-position: 0 32.231405%
}

.flag-gl, .flag-grl {
    background-position: 0 32.644628%
}

.flag-gm, .flag-gmb {
    background-position: 0 33.057851%
}

.flag-gn, .flag-gnb {
    background-position: 0 33.471074%
}

.flag-gp, .flag-glp {
    background-position: 0 33.884298%
}

.flag-gq, .flag-gnq {
    background-position: 0 34.297521%
}

.flag-gr, .flag-grc {
    background-position: 0 34.710744%
}

.flag-gs, .flag-sgs {
    background-position: 0 35.123967%
}

.flag-gt, .flag-gtm {
    background-position: 0 35.53719%
}

.flag-gu, .flag-gum {
    background-position: 0 35.950413%
}

.flag-gw, .flag-gnb {
    background-position: 0 36.363636%
}

.flag-gy, .flag-guy {
    background-position: 0 36.77686%
}

.flag-hk, .flag-hkg {
    background-position: 0 37.190083%
}

.flag-hm, .flag-hmd {
    background-position: 0 37.603306%
}

.flag-hn, .flag-hnd {
    background-position: 0 38.016529%
}

.flag-hr, .flag-hrv {
    background-position: 0 38.429752%
}

.flag-ht, .flag-hti {
    background-position: 0 38.842975%
}

.flag-hu, .flag-hun {
    background-position: 0 39.256198%
}

.flag-id, .flag-idn {
    background-position: 0 39.669421%;
}

.flag-ie, .flag-irl {
    background-position: 0 40.082645%;
}

.flag-il, .flag-isr {
    background-position: 0 40.495868%;
}

.flag-in, .flag-ind {
    background-position: 0 40.909091%;
}

.flag-io, .flag-iot {
    background-position: 0 41.322314%;
}

.flag-iq, .flag-irq {
    background-position: 0 41.735537%;
}

.flag-ir, .flag-irn {
    background-position: 0 42.14876%;
}

.flag-is, .flag-isl {
    background-position: 0 42.561983%;
}

.flag-it, .flag-ita {
    background-position: 0 42.975207%;
}

.flag-jm, .flag-jam {
    background-position: 0 43.38843%;
}

.flag-jo, .flag-jor {
    background-position: 0 43.801653%;
}

.flag-jp, .flag-jpn {
    background-position: 0 44.214876%;
}

.flag-ke, .flag-ken {
    background-position: 0 44.628099%;
}

.flag-kg, .flag-kgz {
    background-position: 0 45.041322%;
}

.flag-kh, .flag-khm {
    background-position: 0 45.454545%;
}

.flag-ki, .flag-kir {
    background-position: 0 45.867769%;
}

.flag-km, .flag-com {
    background-position: 0 46.280992%;
}

.flag-kn, .flag-kna {
    background-position: 0 46.694215%;
}

.flag-kp, .flag-prk {
    background-position: 0 47.107438%;
}

.flag-kr, .flag-kor {
    background-position: 0 47.520661%;
}

.flag-kw, .flag-kwt {
    background-position: 0 47.933884%;
}

.flag-ky, .flag-cym {
    background-position: 0 48.347107%;
}

.flag-kz, .flag-kaz {
    background-position: 0 48.760331%;
}

.flag-la, .flag-lao {
    background-position: 0 49.173554%;
}

.flag-lb, .flag-lbn {
    background-position: 0 49.586777%;
}

.flag-lc, .flag-lca {
    background-position: 0 50%;
}

.flag-li, .flag-lie {
    background-position: 0 50.413223%;
}

.flag-lk, .flag-lka {
    background-position: 0 50.826446%;
}

.flag-lr, .flag-lbr {
    background-position: 0 51.239669%;
}

.flag-ls, .flag-lso {
    background-position: 0 51.652893%;
}

.flag-lt, .flag-ltu {
    background-position: 0 52.066116%;
}

.flag-lu, .flag-lux {
    background-position: 0 52.479339%;
}

.flag-lv, .flag-lva {
    background-position: 0 52.892562%;
}

.flag-ly, .flag-lby {
    background-position: 0 53.305785%;
}

.flag-ma, .flag-mar {
    background-position: 0 53.719008%;
}

.flag-mc, .flag-mco {
    background-position: 0 54.132231%;
}

.flag-md, .flag-mda {
    background-position: 0 54.545455%;
}

.flag-me, .flag-mne {
    background-position: 0 54.958678%;
}

.flag-mg, .flag-mdg {
    background-position: 0 55.371901%;
}

.flag-mh, .flag-mhl {
    background-position: 0 55.785124%;
}

.flag-mk, .flag-mkd {
    background-position: 0 56.198347%;
}

.flag-ml, .flag-mli {
    background-position: 0 56.61157%;
}

.flag-mm, .flag-mmr {
    background-position: 0 57.024793%;
}

.flag-mn, .flag-mng {
    background-position: 0 57.438017%;
}

.flag-mo, .flag-mac {
    background-position: 0 57.85124%;
}

.flag-mp, .flag-mnp {
    background-position: 0 58.264463%;
}

.flag-mq, .flag-mtq {
    background-position: 0 58.677686%;
}

.flag-mr, .flag-mrt {
    background-position: 0 59.090909%;
}

.flag-ms, .flag-msr {
    background-position: 0 59.504132%;
}

.flag-mt, .flag-mlt {
    background-position: 0 59.917355%;
}

.flag-mu, .flag-mus {
    background-position: 0 60.330579%;
}

.flag-mv, .flag-mdv {
    background-position: 0 60.743802%;
}

.flag-mw, .flag-mwi {
    background-position: 0 61.157025%;
}

.flag-mx, .flag-mex {
    background-position: 0 61.570248%;
}

.flag-my, .flag-mys {
    background-position: 0 61.983471%;
}

.flag-mz, .flag-moz {
    background-position: 0 62.396694%;
}

.flag-na, .flag-nam {
    background-position: 0 62.809917%;
}

.flag-nc, .flag-ncl {
    background-position: 0 63.22314%;
}

.flag-ne, .flag-ner {
    background-position: 0 63.636364%;
}

.flag-nf, .flag-nfk {
    background-position: 0 64.049587%;
}

.flag-ng, .flag-nga {
    background-position: 0 64.46281%;
}

.flag-ni, .flag-nic {
    background-position: 0 64.876033%;
}

.flag-nl, .flag-nld {
    background-position: 0 65.289256%;
}

.flag-no, .flag-nor {
    background-position: 0 65.702479%;
}

.flag-np, .flag-npl {
    background-position: 0 66.115702%;
}

.flag-nr, .flag-nru {
    background-position: 0 66.528926%;
}

.flag-nu, .flag-niu {
    background-position: 0 66.942149%;
}

.flag-nz, .flag-nzl {
    background-position: 0 67.355372%;
}

.flag-om, .flag-omn {
    background-position: 0 67.768595%;
}

.flag-pa, .flag-pan {
    background-position: 0 68.181818%;
}

.flag-pe, .flag-per {
    background-position: 0 68.595041%;
}

.flag-pf, .flag-pyf {
    background-position: 0 69.008264%;
}

.flag-pg, .flag-png {
    background-position: 0 69.421488%;
}

.flag-ph, .flag-phl {
    background-position: 0 69.834711%;
}

.flag-pk, .flag-pak {
    background-position: 0 70.247934%;
}

.flag-pl, .flag-pol {
    background-position: 0 70.661157%;
}

.flag-pm, .flag-spm {
    background-position: 0 71.07438%;
}

.flag-pn, .flag-pcn {
    background-position: 0 71.487603%;
}

.flag-pr, .flag-pri {
    background-position: 0 71.900826%;
}

.flag-pt, .flag-prt {
    background-position: 0 72.31405%;
}

.flag-pw, .flag-plw {
    background-position: 0 72.727273%;
}

.flag-py, .flag-pry {
    background-position: 0 73.140496%;
}

.flag-qa, .flag-qat {
    background-position: 0 73.553719%;
}

.flag-re, .flag-reu {
    background-position: 0 73.966942%;
}

.flag-ro, .flag-rou {
    background-position: 0 74.380165%;
}

.flag-rs, .flag-srb {
    background-position: 0 74.793388%;
}

.flag-ru, .flag-rus {
    background-position: 0 75.206612%;
}

.flag-rw, .flag-rwa {
    background-position: 0 75.619835%;
}









.flag-sa {
    background-position: 0 76.033058%
}

.flag-sb {
    background-position: 0 76.446281%
}

.flag-sc {
    background-position: 0 76.859504%
}

.flag-sd {
    background-position: 0 77.272727%
}

.flag-se {
    background-position: 0 77.68595%
}

.flag-sg {
    background-position: 0 78.099174%
}

.flag-sh {
    background-position: 0 78.512397%
}

.flag-si {
    background-position: 0 78.92562%
}

.flag-sj {
    background-position: 0 79.338843%
}

.flag-sk {
    background-position: 0 79.752066%
}

.flag-sl {
    background-position: 0 80.165289%
}

.flag-sm {
    background-position: 0 80.578512%
}

.flag-sn {
    background-position: 0 80.991736%
}

.flag-so {
    background-position: 0 81.404959%
}

.flag-sr {
    background-position: 0 81.818182%
}

.flag-ss {
    background-position: 0 82.231405%
}

.flag-st {
    background-position: 0 82.644628%
}

.flag-sv {
    background-position: 0 83.057851%
}

.flag-sy {
    background-position: 0 83.471074%
}

.flag-sz {
    background-position: 0 83.884298%
}

.flag-tc {
    background-position: 0 84.297521%
}

.flag-td {
    background-position: 0 84.710744%
}

.flag-tf {
    background-position: 0 85.123967%
}

.flag-tg {
    background-position: 0 85.53719%
}

.flag-th {
    background-position: 0 85.950413%
}

.flag-tj {
    background-position: 0 86.363636%
}

.flag-tk {
    background-position: 0 86.77686%
}

.flag-tl {
    background-position: 0 87.190083%
}

.flag-tm {
    background-position: 0 87.603306%
}

.flag-tn {
    background-position: 0 88.016529%
}

.flag-to {
    background-position: 0 88.429752%
}

.flag-tp {
    background-position: 0 88.842975%
}

.flag-tr {
    background-position: 0 89.256198%
}

.flag-tt {
    background-position: 0 89.669421%
}

.flag-tv {
    background-position: 0 90.082645%
}

.flag-tw {
    background-position: 0 90.495868%
}

.flag-ty {
    background-position: 0 90.909091%
}

.flag-tz {
    background-position: 0 91.322314%
}

.flag-ua {
    background-position: 0 91.735537%
}

.flag-ug {
    background-position: 0 92.14876%
}

.flag-gb,
.flag-uk {
    background-position: 0 92.561983%
}

.flag-um {
    background-position: 0 92.975207%
}

.flag-us {
    background-position: 0 93.38843%
}

.flag-uy {
    background-position: 0 93.801653%
}

.flag-uz {
    background-position: 0 94.214876%
}

.flag-va {
    background-position: 0 94.628099%
}

.flag-vc {
    background-position: 0 95.041322%
}

.flag-ve {
    background-position: 0 95.454545%
}

.flag-vg {
    background-position: 0 95.867769%
}

.flag-vi {
    background-position: 0 96.280992%
}

.flag-vn {
    background-position: 0 96.694215%
}

.flag-vu {
    background-position: 0 97.107438%
}

.flag-wf {
    background-position: 0 97.520661%
}

.flag-ws {
    background-position: 0 97.933884%
}

.flag-ye {
    background-position: 0 98.347107%
}

.flag-za {
    background-position: 0 98.760331%
}

.flag-zm {
    background-position: 0 99.173554%
}

.flag-zr {
    background-position: 0 99.586777%
}

.flag-zw {
    background-position: 0 100%
}