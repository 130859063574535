[data-frame="adm-platform-frame-dashboard-vertical"] {
    .cls-frame {
        &-dashboard-vertical {
            height: 100%;
            background-color: #F3F3F9;

            &-grid {
                display: grid;
                grid-template-rows: 1fr;
                grid-template-columns: 25rem 1fr;
                grid-template-areas: "nav main";
                height: 100%;

                @media (min-width: 991.1px) {
                    grid-template-columns: 20rem 1fr;
                }
            }

            &-nav {
                grid-area: nav;
                background-color: #696969;
                height: 100%;
                padding-top: 5rem;

                .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
                    border: 0 none;
                    color: #e5e4e2;
                    background: transparent;
                    border-radius: 6px;
                    padding: 0.2rem 1rem;
                    height: 2rem;
                    font-size: 1.3rem;
                    font-family: "Nunito Sans Medium", sans-serif;
                    font-weight: bold;
                }

                .p-panelmenu .p-panelmenu-panel {
                    padding: 0;
                    overflow: hidden;
                    margin-bottom: 0;
                    border: 1px solid transparent;
                    border-radius: 0;
                    background-color: transparent;
                }

                .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content {
                    color: #334155;
                    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
                    border-radius: 4px;
                    font-size: 1.2rem;
                    font-family: "Nunito Sans Medium", sans-serif;
                    font-weight: bold;
                }

                .p-panelmenu .p-panelmenu-content {
                    margin-top: 0.4rem;
                    padding: 0.8rem 0 0.8rem 0;
                    background: #a9a9a9;
                }

                .p-icon-wrapper {
                    position: absolute;
                    right: 0;
                }

                .p-panelmenu-header-action .p-menuitem-text {
                    color: #e5e4e2;
                }

                .p-panelmenu-header-action:hover .p-menuitem-text {
                    color: #fff;
                }

                .p-panelmenu-header-action .p-menuitem-icon {
                    color: #e5e4e2;
                }

                .p-panelmenu-header-action:hover .p-menuitem-icon {
                    color: #fff;
                }

                .p-panelmenu-header-action .p-submenu-icon {
                    color: #e5e4e2;
                }

                .p-panelmenu-header-action:hover .p-submenu-icon {
                    color: #fff;
                }

                .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
                    color: #232b2b;
                }

                .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
                    color: #232b2b;
                }
            }

            &-main {
                grid-area: main;
                height: 100%;
                border-left: 1.5px solid #000;
                font-size: 1.4rem !important;
                font-family: "Roboto", sans-serif;

                &-grid {
                    display: grid;
                    grid-template-rows: 5rem auto;
                    grid-template-columns: 1fr;
                    grid-template-areas: "header" "container";
                    height: 100%;
                }

                &-header {
                    grid-area: header;
                    background: #000;
                    height: 100%;                    
                }
                
                &-container {
                    grid-area: container;
                    background-color: transparent;
                    padding: 1rem;
                    height: 100%;  
                }
               
                &-form-grid-1-col {
                    display: grid;
                    grid-template-rows: 1fr;
                    grid-template-columns: 1fr;
                    grid-template-areas: "content";
                    height: 100%;
                    margin-left: -0.5rem;
                    margin-right: -1rem;
                }

                &-content {
                    grid-area: content;
                    //white-space: nowrap;
                    overflow: auto;
                    //text-overflow: ellipsis;
                }
            }
        }
    }
}