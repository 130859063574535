[data-frame="app-platform-frame-login-cover"] {
    background: #0F0;

    $primary-color: #00A86B;
    $secondary-color: #4A5568;
    $background-color: #E5E5E5;
    $white: #FFFFFF;
    width: 100%;

    .cls-frame {
        &-login-cover {
            background-color: $background-color;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
            width: 100%;
            padding: 4rem;

            &-container {
                display: flex;
                max-width: 90rem;
                background-color: $white;
                border-radius: 1rem;
                overflow: hidden;
                box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
            }

            &-image {
                background: linear-gradient(to right, #6fa4e0, #0c356a);
                color: $white;
                padding: 4rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 60%;

                &-logo {
                    font-size: 2.4rem;
                    font-weight: bold;
                }

                &-advise {
                    font-style: italic;
                }
            }

            &-content {
                position: relative;
                padding: 4rem;
                width: 40%;

                h2 {
                    margin-bottom: 1rem;
                    color: $secondary-color;
                }

                p {
                    color: $secondary-color;
                    margin-bottom: 1rem;
                }

                &-language {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font-family: "Nunito Sans", sans-serif;
                    font-weight: 600;
                    font-size: 1.5rem;
                    margin-top: -1rem;
                    margin-bottom: 0.8rem;

                    div {
                        margin-right: 0.6rem
                    }
                }

                &-system {
                    &-content {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-weight: bold;
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .cls-frame {
            &-login-cover {
                padding: 2rem;

                &-container {
                    flex-direction: column;
                }

                &-image {
                    width: 100%;
                    padding: 1rem;
                }

                &-content {
                    width: 100%;
                    padding-top: 2rem;
                }
            }
        }
    }
}