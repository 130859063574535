.cls-util {
    &-height-full {
        height: 100% !important;
    }

    &-width-full {
        width: 100% !important;
    }

    &-no-margin {
        margin: 0 !important;
    }

    &-no-margin-bottom {
        margin-bottom: 0 !important;
    }

    &-no-padding {
        padding: 0 !important;
    }

    &-no-padding-bottom {
        padding-bottom: 0 !important;
    }

    &-no-padding-top {
        padding-top: 0 !important;
    }

    &-no-padding-margin-bottom {
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
    }

    &-no-padding-tp-bm {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }

    &-font-bold {
        font-weight: bold;
    }

    &-font-normal {
        font-weight: normal;
    }

    &-font-family-bold {
        font-family: var(--font-family-bold);
    }

    &-font-family-menu-bold {
        font-family: var(--font-family-menu-bold);
    }

    &-font-family {
        font-family: var(--font-family);
    }

    &-font-family-menu {
        font-family: var(--font-family-menu);
    }

    &-center-text {
        text-align: center;
    }

    &-margin-bottom-big {
        margin-bottom: 8rem;
    }

    &-margin-bottom-medium {
        margin-bottom: 4rem;
    }

    &-margin-bottom-small {
        margin-bottom: 2rem;
    }

    &-margin-top-big {
        margin-top: 8rem;
    }

    &-margin-top-huge {
        margin-top: 10rem;
    }

    &-flex-grow-1 {
        flex-grow: 1;
    }

    &-display-none {
        display: none !important;
    }

    &-alert-danger {
        background-color: #721c24;
    }

    &-alert-warning {
        background-color: #856404;
    }

    &-alert-success {
        background-color: #155724;
    }

    &-color-payne-grey {
        background-color: #536878;
    }

    &-color-dark-blue-gray {
        background-color: #3b5998;
    }

    &-color-deep-taupe {
        background-color: #7e5e60;
    }

    &-color-dark-slate-gray {
        background-color: #2f4f4f;
    }

    &-color-dark-red-brown {
        background-color: #a52a2a;
    }

    &-color-up-marrow {
        background-color: #7b1113;
    }

    &-color-dark-tan {
        background-color: #918151;
    }

    &-color-pastel-brown {
        background-color: #836953;
    }

    &-color-burnt-orange {
        background-color: #cc5500;
    }

    &-color-usc-gold {
        background-color: #ffcc00;
    }

    &-color-limerick {
        background-color: #9dc209;
    }

    &-color-dark-olive-green {
        background-color: #556b2f;
    }

    &-color-teal {
        background-color: #008080;
    }

    &-color-dark-cyan {
        background-color: #008b8b;
    }

    &-color-corn-flower-blue {
        background-color: #6495ed;
    }

    &-color-air-force-blue {
        background-color: #00308f;
    }

    &-color-medium-slate-blue {
        background-color: #7b68ee;
    }

    &-color-dark-orchid {
        background-color: #9932cc;
    }

    &-color-tyrian-purple {
        background-color: #66023c;
    }

    &-color-indigo {
        background-color: #4b0082;
    }

    &-color-purple-taupe {
        background-color: #50404d;
    }

    &-color-magenta {
        background-color: #ca1f7b;
    }
}