[data-platform="adm-platform"] {
    * {
        box-sizing: border-box;
    }

    .cls-pge {
        &-login-form {
            background-color: transparent;
            margin-bottom: 2rem;
        }

        &-adm-form {
            background-color: transparent;
            height: 100%;
        }

        &-app-list {
            background-color: transparent;
            height: 100%;
            width: 100%;
        }
    }

    .cls-cmp {
        // Classes PRIMITIVAS. Elementos de estrutura em Geral: panel, button, input..
        &-ptv {
            &-grid {
                &-form {
                    margin-top: 0;
                }
            }

            &-dialog {
                &-form {
                    &-box {
                        padding: 0.5rem;
                        background-color: #FFF;
                        border-radius: 1rem;
                    }

                    &-last-panel {
                        margin-bottom: 0 !important;
                    }
                }

                &-confirm {
                    &-delete {
                        &-frame {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding: 1.5rem 2rem;
                            border-radius: 1rem;
                            background: linear-gradient(to top, #09203f 0%, #0c356a 100%);
                            color: #FFF;
                            margin: -0.1rem;
                        }

                        &-icon {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50rem;
                            width: 5rem;
                            height: 5rem;
                            font-size: 3.5rem;
                            background-color: #cc0000;
                            color: #FFF
                        }

                        &-content {
                            h1 {
                                font-size: 1.6rem;
                                font-weight: bold;
                                margin: 1rem 0;
                                padding: 0;
                                text-transform: uppercase;
                                text-align: center;
                            }

                            p {
                                font-size: 1.5rem;
                                margin-top: 0.5rem;
                                text-align: center;
                            }
                        }

                        &-toolbar {
                            .p-toolbar {
                                border: none;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

            &-table {
                &-page-list {
                    .p-datatable .p-datatable-thead>tr>th {
                        background-color: #ff7f50;
                        color: #FFF;
                        font-weight: bold;
                        letter-spacing: 0.1rem;
                        padding: 0.5rem 1rem;
                    }

                    .p-datatable .p-datatable-tbody>tr>td {
                        font-size: 1.3rem;
                        font-family: "Nunito Sans Semibold", sans-serif;
                        letter-spacing: 0.05rem;
                        padding: 0.3rem 1rem;
                    }

                    &-td {
                        &-image {
                            width: 5rem;
                        }

                        &-button {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;

                            .p-button:first-child {
                                margin-right: 0.5rem;
                            }

                            @media (max-width: 767px) {
                                flex-direction: column;
                                align-items: center;

                                .p-button:first-child {
                                    margin-bottom: 0.5rem;
                                }
                            }

                            @media (min-width: 768px) and (max-width: 1024px) {
                                justify-content: space-between;
                            }

                            .p-button {
                                height: 2.5rem;
                                width: 2.5rem;
                            }
                        }
                    }

                    &-caption {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &-left {}

                        &-right {
                            display: flex;
                            align-items: center;

                            div {
                                margin-left: 0.5rem !important;
                            }
                        }
                    }

                    &-input {
                        &-text {
                            background-color: var(--gray-100);
                        }

                        &-number {
                            input {
                                background-color: var(--gray-100);
                            }
                        }

                        &-dropdown {
                            .p-dropdown {
                                background-color: var(--gray-100);
                            }


                        }
                    }
                }

                &-form-list {
                    .p-datatable .p-datatable-thead>tr>th {
                        font-weight: bold;
                        font-size: 0.9rem;
                        color: #FFF;
                        background: #ff7f50; //#0073cf;
                        letter-spacing: 0.1rem;
                        padding: 0.2rem 1rem;
                    }

                    &-caption {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &-left {}

                        &-right {
                            display: flex;
                            align-items: center;

                            div {
                                margin-left: 0.5rem !important;
                            }
                        }
                    }

                    &-td {
                        &-button {
                            display: flex;

                            .p-button {
                                margin-left: 0.3rem;
                            }
                        }
                    }
                }
            }

            &-form {
                label {
                    color: #a9a9a9;
                }

                .p-inputgroup-addon{
                    font-weight: bold;
                    background: #ff4500;
                    color: #FFF;
                    padding: 0 0.5rem;
                    min-width: 3rem;
                    letter-spacing: 0.1rem;
                }
                
                &-frame {
                    .field {
                        margin-bottom: 0;
                    }
                }

                &-gap {
                    height: 0.5rem;
                }

                &-advice {
                    width: 100%;
                    text-align: right;
                    color: #133E87 !important;
                    margin-bottom: 0;

                    span {
                        font-size: 1.1rem;
                        margin-left: 0.5rem;
                    }
                }                
            }

            &-panel {
                &-bottom {
                    height: 1rem;
                }

                &-default {
                    display: block;
                    margin-bottom: 1rem;

                    &>.p-panel {
                        border: 1px solid rgba(255, 69, 0, 0.2);
                    }

                    &>.p-panel>.p-panel-header {
                        background: #ff4500;
                        color: #FFF;
                        padding: 0.5rem 1rem;
                    }

                    .p-panel .p-panel-content {
                        padding: 1rem;
                        padding-bottom: 0;
                    }

                    .p-panel .p-panel-header .p-panel-header-icon {
                        background: #FFF;
                        //color: var(--primary-color);
                        width: 1.6rem;
                        height: 1.5rem;
                        font-size: 1rem;
                        font-weight: bold;
                    }

                    .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
                        background: var(--gray-200);
                        color: #000;
                    }

                    &-header {
                        &-title {
                            h2 {
                                padding: 0;
                                margin: 0;
                                font-weight: bold;
                                letter-spacing: 0.15rem;
                                font-size: 1.1rem;
                            }
                        }

                        span {
                            display: inline-block;
                            margin-left: 0.5rem;
                        }
                    }
                }

                &-topic {
                    display: block;
                    margin-bottom: 1rem;

                    &>.p-panel {
                        border: 1px solid rgba(255, 228, 181, 0.8);
                    }

                    &>.p-panel>.p-panel-header {
                        background: #ffe4b5;
                        color: #000;
                        padding: 0.2rem 0.5rem;
                    }

                    .p-panel .p-panel-content {
                        padding: 1rem;
                        padding-bottom: 0;
                    }

                    &-header {
                        &-title {
                            h3 {
                                padding: 0;
                                margin: 0;
                                font-weight: bold;
                                letter-spacing: 0.15rem;
                                font-size: 1rem;
                            }
                        }

                        span {
                            display: inline-block;
                            margin-left: 0.5rem;
                        }
                    }
                }
            }

            &-tabview {
                &-page {
                    width: 100%;

                    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
                        background: transparent;
                        color: #e5e4e2;
                        padding: 0.5rem 1rem;
                        border-top-right-radius: 0;
                        border-top-left-radius: 0;
                    }

                    .p-tabview-nav-content {
                        background-color: #696969;
                    }

                    .p-tabview .p-tabview-nav {
                        background-color: #696969;
                    }

                    .p-tabview .p-tabview-panels {
                        background: #FFF;
                        padding: 1rem;
                        border: 0 none;
                        color: #334155;
                        border-bottom-right-radius: 6px;
                        border-bottom-left-radius: 6px;
                    }

                    &-tabpanel {
                        &-header {
                            font-size: 1.2rem;
                            white-space: nowrap;

                            span {
                                margin-left: 0.4rem;
                                white-space: nowrap;
                            }
                        }

                        &-data {}
                    }
                }
            }

            &-toolbar {
                &-page {
                    display: block;

                    .p-toolbar {
                        background-color: #FFF;
                    }
                }

                &-dialog {
                    display: block;
                    margin-top: 0.5rem;
                }
            }

            &-button {
                font-size: 1.1rem;
                text-transform: uppercase;
                letter-spacing: 0.1rem;

                &-toolbar {
                    margin-left: 0.5rem;
                }
            }

            &-toast {
                .p-toast {
                    width: 45rem;
                    white-space: pre-line !important;
                }

                .p-toast-message-content {
                    border-left: 8px solid;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                .p-icon-wrapper {
                    margin-top: 0.1rem;

                    svg {
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }

                .p-toast-icon-close {
                    position: absolute;
                    top: 0.3rem;
                    right: 0.3rem;
                    font-weight: bold;
                    font-size: 2rem;
                    color: #FFF;
                    background-color: #000;
                }

                &-basic {
                    &-header {
                        display: flex;
                        width: 100%;
                        margin-top: -0.5rem;
                        border-bottom: 1px dashed;
                        margin-bottom: 0.7rem;
                        font-size: 1.6rem;
                        font-weight: bold;

                        &-summary {
                            margin-left: 0.5rem;
                            flex-grow: 1;
                            text-transform: uppercase;
                        }
                    }

                    &-content {
                        display: block;
                        width: 100%;
                        font-size: 1.4rem;
                        color: #000;
                    }
                }
            }
        }

        // Classes ESPECIFICO. Elementos de algum componente Especifico: Login
        &-esp {
            &-title-page {
                display: flex;
                align-items: flex-start;
                padding-bottom: 0.5rem;
                font-family: "Rubik Medium", sans-serif;
                letter-spacing: 0.1rem;
                //border-bottom: 2px solid #777;
                margin-bottom: 0;

                &-icon {
                    font-size: 2.4rem;
                    margin-right: 0.5rem;

                    @media (max-width: 767px) {
                        font-size: 1.5rem;
                        line-height: 1.5rem;
                    }
                }

                &-content {
                    h1 {
                        font-size: 1.5rem;
                        margin: 0;
                        padding: 0;
                        text-transform: uppercase;

                        @media (max-width: 767px) {
                            font-size: 1.5rem;
                            line-height: 1.6rem;
                        }
                    }

                    &-subtitle {
                        font-size: 1.1rem;
                        margin-top: -0.8rem;

                        @media (max-width: 767px) {
                            display: none;
                        }
                    }
                }
            }

            &-login {
                &-input {
                    position: relative;
                    margin-bottom: 1rem;

                    label {
                        color: #4A5568;
                        font-size: 1.2rem;
                        text-transform: uppercase;
                    }

                    input {
                        font-size: 1.8rem;
                    }
                }

                &-password-forgot {
                    position: absolute;
                    color: #00A86B;

                    font-size: 1.2rem;
                    right: 0.3rem;
                    top: -0.1rem;

                    a {
                        text-decoration: none;
                    }
                }

                &-button {
                    button {
                        display: block;
                        width: 100%;
                        text-align: center;
                        font-size: 1.23rem;
                        font-weight: bold;
                        margin-top: 2.5rem;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        text-transform: uppercase;
                    }
                }

                &-sign-up {
                    text-align: center;
                    margin-top: 2rem;
                    font-size: 1.4rem;

                    a {
                        color: #00A86B;
                        text-decoration: none;
                    }
                }
            }

            &-preview-path {
                & .p-panel {
                    height: 19.4rem;
                    overflow: auto;
                }

                .p-tree {
                    padding: 0;
                }
            }
        }
    }
}