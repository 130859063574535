// ----- NORMALIZE ----- //
@import 'normalize.css';

// ----- PRIMENG ---- //
@import 'primeflex/primeflex.scss';
@import "primeicons/primeicons.css";
@import "primeng/resources/themes/aura-light-blue/theme.css"; // DEFAULT THEME - Define Color Scheme
@import 'primeng/resources/primeng.css';

// ----- FONTAWESOME ---- // 
// https://fontawesome.com/docs/web/use-with/scss#adding-font-awesome-to-your-compile
$fa-font-path: "../../fontawesome-free-6.6.0-web/webfonts";
@import '../../archives/fontawesome-free-6.6.0-web/scss/fontawesome.scss';
@import '../../archives/fontawesome-free-6.6.0-web/scss/solid.scss';
@import '../../archives/fontawesome-free-6.6.0-web/scss/brands.scss';
@import '../../archives/fontawesome-free-6.6.0-web/scss/regular.scss';

// ----- COMMON: BASE STYLE ---- //
@import './common/base/_flags.scss';
@import './common/base/_typography.scss';
@import './common/base/_base.scss'; 
@import './common/base/_utils.scss'; 
@import './common/base/_classes.scss';
@import './common/base/_animations.scss';

// ----- COMMON: ABSTRACTS STYLE ---- //
@import './common/abstracts/_variables.scss';
@import './common/abstracts/_mixins.scss';
@import './common/abstracts/_functions.scss';

// ----- COMMON: FRAMES ---- //
@import './modules/admin-platform/admin_platform.scss';
@import './modules/app-platform/app_platform.scss'; 

@import './common/primeng/_ui_primeng.scss'; 