/* *,
*::after,
*::before
{
    // Todos os elementos, forçando mesmo, vão herdar esta propriedade - que foi definido no elemento "body"
    box-sizing: inherit; 
}*/

* {
    box-sizing: border-box;   
}

html {
    // ROOT ELEMENT...
    // REM não funciona par ao IE 9 e menores...
    // Não é uma boa ideia definir o tamanho em pixel, pois o usuário pode querer mudar o tamanho para visualizar melhor e isto pode não refletir...
    // A melhor maneira é usar a porcentagem do valor fornecido pelo Browser. O valor default, geralmente é 16px.
    // Para termos o equivalente a 10px, a gente divide 10/16 = 62.5%
    font-size: 62.5%;

    height: 100%;
}

body {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    color: #777;
    height: 100%;
    line-height: 1.7;

    box-sizing: border-box; // Determina que o Height e o Width vão considerar o CONTENT + PADDING + BORDER para calculo de tamanho
}