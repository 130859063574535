[data-platform="app-platform"] {
    * {
        box-sizing: border-box;
    }

    //--------- DATE-PICKER --------- //
    .p-datepicker {
        padding: 0.7rem;
        background: #f4f0ec;
        color: #334155;
        border: 1px solid #cbd5e1;
        border-radius: 6px;
    }

    .p-disabled {
        opacity: 0.3;
    }

    .p-datepicker .p-datepicker-header {
        padding: 0.5rem 0 0.5rem 0;
        color: #FFF;
        background: #0C356A;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-prev,
    .p-datepicker .p-datepicker-header .p-datepicker-next {
        color: #FFF;
        margin-right: 0.8rem;
        margin-left: 0.8rem;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
    .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
        color: #000;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        color: #FFF;
        font-weight: bold;
        font-size: 1.1rem;
        font-family: "Roboto";
        text-transform: uppercase;
        letter-spacing: 0.1rem;
    }

    .p-datepicker table {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0.5rem 0 0 0;
        font-family: "Roboto";
    }

    .p-datepicker table td>span {
        width: 2.5rem;
        height: 2.5rem;
    }

    .p-datepicker table td>span.p-highlight {
        color: #FFF;
        background: #006600;
    }

    .p-datepicker table td.p-datepicker-today>span {
        background: #afeeee;
        color: #0f172a;
        border-color: transparent;
    }

    //--------------------------------//

    .cls-pge {
        &-login-form {
            background-color: transparent;
            margin-bottom: 2rem;
        }

        &-app-form {
            background-color: transparent;
            height: 100%;
        }

        &-app-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            background-color: transparent;
            height: 100%;
            width: 100%;
        }

        &-app-dashboard {
            background-color: transparent;
            height: 100%;
            width: 100%;
        }

        &-app-card {
            background-color: transparent;
            height: 100%;
            width: 100%;
        }

        &-app-calendar {
            background-color: transparent;
            height: 100%;
            width: 100%;
        }

        &-app-info {
            background-color: transparent;
            height: 100%;
            width: 100%;
        }
    }

    .cls-cmp {
        &-full {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            width: 100%;
        }

        // Classes PRIMITIVAS. Elementos de estrutura em Geral: panel, button, input..
        &-ptv {
            &-grid {
                &-form {
                    margin-top: 0;
                }
            }

            &-dialog {
                &-form {
                    &-box {
                        padding: 0.5rem;
                        background-color: #FFF;
                        border-radius: 1rem;

                        overflow: auto;
                    }

                    &-last-panel {
                        margin-bottom: 0 !important;
                    }
                }

                &-confirm {
                    &-delete {
                        &-frame {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding: 1.5rem 2rem;
                            border-radius: 1rem;
                            background: linear-gradient(to top, #09203f 0%, #0c356a 100%);
                            color: #FFF;
                            margin: -0.1rem;
                        }

                        &-icon {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50rem;
                            width: 5rem;
                            height: 5rem;
                            font-size: 3.5rem;
                            background-color: #cc0000;
                            color: #FFF
                        }

                        &-content {
                            h1 {
                                font-size: 1.6rem;
                                font-weight: bold;
                                margin: 1rem 0;
                                padding: 0;
                                text-transform: uppercase;
                                text-align: center;
                            }

                            p {
                                font-size: 1.5rem;
                                margin-top: 0.5rem;
                                text-align: center;
                                white-space: pre-wrap
                            }
                        }

                        &-toolbar {
                            .p-toolbar {
                                border: none;
                                background-color: transparent;
                            }
                        }
                    }
                }

                &-advice {
                    &-frame {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 1.5rem 2rem;
                        border-radius: 1rem;
                        background: linear-gradient(to top, #09203f 0%, #0c356a 100%);
                        color: #FFF;
                        margin: -0.1rem;
                    }

                    &-icon {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50rem;
                        width: 5rem;
                        height: 5rem;
                        font-size: 3.5rem;
                        background-color: #0ea5e9;
                        color: #FFF
                    }

                    &-content {
                        h1 {
                            font-size: 1.6rem;
                            font-weight: bold;
                            margin: 1rem 0;
                            padding: 0;
                            text-transform: uppercase;
                            text-align: center;
                        }

                        p {
                            font-size: 1.5rem;
                            margin-top: 0.5rem;
                            text-align: center;
                            white-space: pre-wrap;
                        }
                    }

                    &-toolbar {
                        .p-toolbar {
                            border: none;
                            background-color: transparent;
                        }

                        .p-button {
                            width: 10rem !important;
                        }
                    }
                }

                &-waiting {
                    .p-dialog {
                        min-width: 35vw;
                        //https://cssgradient.io/gradient-backgrounds/
                        //https://gradienthunt.com/
                        //background-image: linear-gradient( 359.8deg,  rgba(252,255,222,1) 2.2%, rgba(182,241,171,1) 99.3% );
                        //background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(234,249,249,0.67) 0.1%, rgba(239,249,251,0.63) 90.1% );
                        //background-image: linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% );                       
                        //background-image: radial-gradient( circle 1224px at 10.6% 8.8%,  rgba(255,255,255,1) 0%, rgba(153,202,251,1) 100.2% );
                        //background-image: linear-gradient( 109.6deg,  rgba(204,228,247,1) 11.2%, rgba(237,246,250,1) 100.2% );   
                        //background-image: linear-gradient( 92.7deg,  rgba(245,212,212,1) 8.5%, rgba(252,251,224,1) 90.2% );
                        background-image: linear-gradient(44.8deg, rgba(255, 136, 102, 0.67) -53.1%, rgba(255, 221, 136, 0.28) 49%);
                    }

                    &-card {
                        display: flex;
                        flex-direction: column;

                        &-header {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            background-color: #0C356A;
                            color: #FFF;
                            padding: 0.2rem 1.5rem;
                            font-size: 1.9rem;
                            border-top-left-radius: 1.3rem;
                            border-top-right-radius: 1.3rem;
                            font-family: 'Racing Sans One';
                            letter-spacing: 0.1rem;
                            text-transform: uppercase;

                            &-button {
                                margin-top: -0.8rem;
                            }
                        }

                        &-advice {
                            font-size: 1.3rem;
                            padding: 0 2rem;
                            color: #0C356A;
                            text-align: right;
                            margin-bottom: -1.8rem;
                        }

                        &-content {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            background-color: transparent;
                            font-family: 'Bruno Ace SC';
                            font-style: italic;
                            color: #64748b;

                            padding: 3rem 2rem 0 2rem;
                            font-size: 2rem;
                            font-weight: bold;
                            text-transform: uppercase;

                            div {
                                margin-bottom: 2rem;
                            }

                            background-color: transparent;
                        }

                        &-footer {
                            background-color: transparent;
                            padding: 0 1.5rem 0.2rem;
                            text-align: right;
                            font-size: 1.2rem;
                            font-weight: bold;
                            margin-top: -1rem;

                            background-color: transparent;
                            color: #64748b;
                        }
                    }
                }
            }

            &-table {
                &-page-list {
                    .p-datatable .p-datatable-thead>tr>th {
                        background-color: #2a52be;
                        color: #FFF;
                        font-weight: bold;
                        letter-spacing: 0.1rem;
                        padding: 0.5rem 1rem;
                    }

                    .p-datatable .p-datatable-tbody>tr>td {
                        font-size: 1.3rem;
                        font-family: "Nunito Sans Semibold", sans-serif;
                        letter-spacing: 0.05rem;
                        padding: 0.3rem 1rem;
                    }

                    &-td {
                        &-image {
                            width: 5rem;
                        }

                        &-aerodrome {
                            &-icao {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 0.5rem;
                                width: 100%;

                                &-label {
                                    margin-top: 0.3rem;
                                }
                            }

                            &-name {
                                font-size: 0.9rem;
                            }
                        }

                        &-button {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;

                            .p-button:first-child {
                                margin-right: 0.5rem;
                            }

                            @media (max-width: 767px) {
                                flex-direction: column;
                                align-items: center;

                                .p-button:first-child {
                                    margin-bottom: 0.5rem;
                                }
                            }

                            @media (min-width: 768px) and (max-width: 1024px) {
                                justify-content: space-between;
                            }

                            .p-button {
                                height: 2.5rem;
                                width: 2.5rem;
                            }
                        }
                    }

                    &-row {
                        &-new {
                            background-color: #fcffa4;
                        }

                        &-edit {
                            background-color: #87ceeb;
                        }
                    }

                    &-caption {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &-left {}

                        &-right {
                            display: flex;
                            align-items: center;

                            div {
                                margin-left: 0.5rem !important;
                            }
                        }
                    }

                    &-input {
                        &-show {
                            font-size: 1.4rem;
                        }

                        &-number {
                            p-inputNumber {
                                input {
                                    background-color: #fbf7f1;
                                    font-weight: bold;
                                    font-size: 1.2rem;
                                    border-top-right-radius: 0;
                                    border-bottom-right-radius: 0;
                                    min-width: 8rem;
                                }
                            }
                        }

                        &-text {
                            input {
                                background-color: #fbf7f1;
                                font-weight: bold;
                                font-size: 1.2rem;
                                width: 100%;
                                text-transform: uppercase;
                                min-width: 8rem;
                            }
                        }

                        &-dropdown {
                            .p-inputtext {
                                padding: 0.2rem 0.75rem;
                            }

                            .p-dropdown {
                                background-color: #fbf7f1;
                                width: 100%;
                                min-width: 8rem;
                            }
                        }

                        /*&-number {
                            width: 100%;

                            input {
                                background-color: #fbf7f1;

                            }

                            .p-inputnumber {
                                width: 100%;
                            }
                        }

                        */
                    }
                }

                &-form-list {
                    .p-datatable .p-datatable-thead>tr>th {
                        font-weight: bold;
                        font-size: 0.9rem;
                        color: #FFF;
                        background: #2a52be; //#0073cf;
                        letter-spacing: 0.1rem;
                        padding: 0.2rem 1rem;
                    }

                    &-caption {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &-left {}

                        &-right {
                            display: flex;
                            align-items: center;

                            div {
                                margin-left: 0.5rem !important;
                            }
                        }
                    }

                    &-td {
                        &-button {
                            display: flex;

                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;

                            .p-button:first-child {
                                margin-right: 0.5rem;
                            }
                        }
                    }
                }
            }

            &-form {
                label {
                    color: #4c77af;
                    font-size: 1.1rem;
                    font-family: "Poetsen One", sans-serif;
                    letter-spacing: 0.1rem;
                }

                .p-inputgroup-addon {
                    font-weight: bold;
                    background: #0C356A;
                    color: #FFF;
                    padding: 0 0.5rem;
                    min-width: 3rem;
                    letter-spacing: 0.1rem;
                }

                input,
                textarea,
                .p-dropdown-label,
                .cls-ui-primeng-field-show-input div {
                    color: #000;
                    font-family: "Open Sans";
                    font-weight: 600;
                    letter-spacing: 0.1rem;
                }




                &-frame {
                    .field {
                        margin-bottom: 0;
                    }
                }

                &-gap {
                    height: 0.5rem;
                }

                &-advice {
                    width: 100%;
                    text-align: right;
                    color: #133E87 !important;
                    margin-bottom: 0;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;

                    span {
                        font-size: 1.1rem;
                        margin-left: 0.5rem;
                    }
                }

                &-textarea {
                    &-csv {
                        textarea {
                            font-family: "Open Sans";
                            font-weight: 600;
                            letter-spacing: 0.1rem;
                            font-size: 1.5rem;
                            color: #000;
                            height: 30rem;
                            max-height: 30rem;
                            width: 100%;
                            padding: 0.5rem;
                            border: 1px solid #0C356A;
                            border-radius: 0.5rem;
                            overflow: auto;
                        }

                        &-domains {
                            height: 31.5rem;
                            max-height: 31.5rem;

                            .p-fieldset-legend-text {
                                font-size: 1.3rem;
                            }

                            .p-fieldset-content {
                                font-size: 1.2rem;
                                padding: 0.5rem;
                            }
                        }
                    }
                }
            }

            &-panel {
                &-bottom {
                    height: 1rem;
                }

                &-default {
                    display: block;
                    margin-bottom: 1rem;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                    &>.p-panel {
                        border: 1px solid #0C356A;
                    }

                    &>.p-panel>.p-panel-header {
                        background: #0C356A;
                        color: #FFF;
                        padding: 0.5rem 1rem;
                    }

                    .p-panel .p-panel-content {
                        padding: 1rem;
                        padding-bottom: 0;
                    }

                    .p-panel .p-panel-header .p-panel-header-icon {
                        background: #FFF;
                        //color: var(--primary-color);
                        width: 1.6rem;
                        height: 1.5rem;
                        font-size: 1rem;
                        font-weight: bold;
                    }

                    .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
                        background: var(--gray-200);
                        color: #000;
                    }

                    &-header {
                        &-title {
                            h2 {
                                padding: 0;
                                margin: 0;
                                font-weight: bold;
                                letter-spacing: 0.15rem;
                                font-size: 1rem;
                            }
                        }

                        span {
                            display: inline-block;
                            margin-left: 0.5rem;
                        }
                    }
                }

                &-topic {
                    display: block;
                    margin-bottom: 1rem;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                    &>.p-panel {
                        border: 1px solid rgba(255, 228, 181, 0.8);
                    }

                    &>.p-panel>.p-panel-header {
                        background: #ffe4b5;
                        color: #000;
                        padding: 0.2rem 0.5rem;
                    }

                    .p-panel .p-panel-content {
                        padding: 1rem;
                        padding-bottom: 0;
                    }

                    &-header {
                        &-title {
                            h3 {
                                padding: 0;
                                margin: 0;
                                font-weight: bold;
                                letter-spacing: 0.15rem;
                                font-size: 1rem;
                            }
                        }

                        span {
                            display: inline-block;
                            margin-left: 0.5rem;
                        }
                    }
                }

                &-aside {
                    display: block;
                    margin-bottom: 1rem;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                    &>.p-panel {
                        border: 1px solid #0C356A;
                    }

                    &>.p-panel>.p-panel-header {
                        background: #0C356A;
                        color: #FFF;
                        padding: 0.2rem 0.5rem;
                    }

                    .p-panel .p-panel-content {
                        padding: 1rem;
                        padding-bottom: 0;
                    }

                    &-header {
                        &-title {
                            h3 {
                                padding: 0;
                                margin: 0;
                                font-weight: bold;
                                letter-spacing: 0.15rem;
                                font-size: 1rem;
                            }
                        }

                        span {
                            display: inline-block;
                            margin-left: 0.5rem;
                        }
                    }
                }
            }

            &-tabview {
                &-page {
                    width: 100%;

                    .p-tabview {
                        border: 1px solid #E5E4E2;
                        border-radius: 6px;
                    }


                    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
                        background: transparent;
                        color: #0c356a;
                        padding: 0.5rem 1rem;
                        border-top-right-radius: 0;
                        border-top-left-radius: 0;
                    }

                    .p-tabview-nav-content {
                        background-color: #e5e4e2;
                        border-top-left-radius: 6px;
                        border-top-right-radius: 6px;
                    }

                    .p-tabview .p-tabview-nav {
                        background-color: #e5e4e2;
                    }

                    .p-tabview .p-tabview-panels {
                        background: #FFF;
                        padding: 1rem;
                        padding-bottom: 0;
                        border: 0 none;
                        color: #334155;
                        border-bottom-right-radius: 6px;
                        border-bottom-left-radius: 6px;
                    }

                    &-tabpanel {
                        &-header {
                            font-size: 1.2rem;
                            white-space: nowrap;

                            span {
                                margin-left: 0.4rem;
                                white-space: nowrap;
                            }
                        }

                        &-data {
                            height: 100%;
                            padding-bottom: 0.5rem;
                        }
                    }
                }

                &-form {
                    display: block;
                    margin-top: -1rem;
                    margin-left: -1rem;
                    margin-right: -1rem;

                    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
                        background: transparent;
                        color: #0c356a;
                        padding: 0.5rem 1rem;
                        border-top-right-radius: 0;
                        border-top-left-radius: 0;
                    }

                    .p-tabview-nav-content {
                        background-color: #e5e4e2;
                    }

                    .p-tabview .p-tabview-nav {
                        background-color: #e5e4e2;
                    }

                    .p-tabview .p-tabview-panels {
                        background: #FFF;
                        padding: 1rem;
                        padding-bottom: 0;
                        border: 0 none;
                        color: #334155;
                        border-bottom-right-radius: 6px;
                        border-bottom-left-radius: 6px;
                    }

                    &-tabpanel {
                        &-header {
                            font-size: 1.2rem;
                            white-space: nowrap;

                            span {
                                margin-left: 0.4rem;
                                white-space: nowrap;
                            }
                        }

                        &-data {
                            height: 100%;
                        }
                    }
                }
            }

            &-toolbar {
                &-page {
                    display: block;
                    margin-bottom: 0.8rem;
                }

                &-dialog {
                    display: block;
                    margin-top: 0.5rem;
                }
            }

            &-button {
                font-size: 1.1rem;
                text-transform: uppercase;
                letter-spacing: 0.1rem;

                &-toolbar {
                    margin-left: 0.5rem;
                }
            }

            &-fieldset {
                font-size: 1.1rem;
                padding-bottom: 0;

                legend {
                    margin-bottom: 0;

                    span {
                        padding-bottom: 0;
                    }
                }

                &-bottom {
                    margin-bottom: 1rem;
                }
            }

            &-toast {
                .p-toast {
                    width: 45rem;
                    white-space: pre-line !important; // Permite quebra de linha no texto do toast (p-toast).
                }

                .p-toast-message-content {
                    border-left: 8px solid;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                .p-icon-wrapper {
                    margin-top: 0.1rem;

                    svg {
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }

                .p-toast-icon-close {
                    position: absolute;
                    top: 0.3rem;
                    right: 0.3rem;
                    font-weight: bold;
                    font-size: 2rem;
                    color: inherit;

                    &:hover {
                        color: #000;
                    }
                }

                &-basic {
                    &-header {
                        display: flex;
                        width: 100%;
                        margin-top: -0.5rem;
                        border-bottom: 1px dashed;
                        margin-bottom: 0.7rem;
                        font-size: 1.3rem;
                        font-weight: 500;

                        &-summary {
                            margin-left: 0.5rem;
                            flex-grow: 1;
                            text-transform: uppercase;
                        }
                    }

                    &-content {
                        display: block;
                        width: 100%;
                        font-size: 1.2rem;
                        color: #000;
                    }
                }
            }

            &-messages {

                .p-message-detail,
                .p-message-icon {
                    font-size: 1.2rem;
                }

            }
        }

        // Classes ESPECIFICO. Elementos de algum componente Especifico: Login,
        &-esp {
            &-cg {
                //height: 70vh;
                //overflow: scroll;

                &-fieldset {
                    fieldset {
                        padding: 0.35em 0.75em 0.35em;
                    }

                    .p-fieldset-legend-text {
                        font-size: 1.1rem;
                        font-weight: bold;
                        font-family: "Roboto";
                        letter-spacing: 0.1rem;

                    }

                    .p-fieldset-legend {
                        margin-bottom: 0;
                    }
                }

                &-preview {
                    height: 70vh;
                    overflow: scroll;

                    &-pdf {
                        &-margin-top {
                            &-first {
                                height: 0.40cm;
                            }

                            &-others {
                                height: 0.80cm;
                            }
                        }

                        &-page {

                            &-first,
                            &-others {
                                position: relative;
                                width: 210mm;
                                height: 297mm;
                                padding: 2mm;
                                margin-left: 3mm;
                                margin-right: 3mm;
                                border: 2px solid #000;
                            }

                            &-first {
                                &-bottom {
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    width: 100%;

                                }
                            }
                        }
                    }

                    &-top {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        background-color: #0C356A;
                        height: 2.7rem;
                        padding: 0 1rem;
                        margin-top: -2mm;
                        margin-left: -2mm;
                        margin-right: -2mm;

                        &-image {
                            flex-grow: 0;

                            img {
                                display: block;
                                width: 7rem;
                            }
                        }

                        &-title {
                            flex-grow: 1;
                            font-size: 1.2rem;
                            font-family: "Open Sans";
                            color: #FFF;
                            margin-left: 0.5rem;
                            text-align: center;
                            font-weight: 600;
                            letter-spacing: 0.2rem;
                        }
                    }

                    &-header {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-left: -0.75rem;
                        margin-right: -0.75rem;

                        &-image {
                            img {
                                display: block;
                                width: 7rem;
                                height: 5rem;
                            }
                        }

                        &-content {
                            background-color: #f5f5f5;
                            width: 100%;
                            padding: 0.1rem;
                            font-weight: bold;
                            font-family: "Open Sans";

                            &-row {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                padding: 0 0.5rem;
                                font-size: 1.1rem;
                                letter-spacing: 0.1rem;
                                color: #0C356A;

                                &-label {
                                    font-size: 0.9rem;
                                    color: rgba(12, 53, 106, 0.5);
                                }

                                &-mark {
                                    width: 25%;
                                    padding: 0.2rem 0;
                                }

                                &-sheet-name {
                                    width: 75%;
                                    padding: 0.2rem 0;
                                }

                                &-icao-code {
                                    width: 25%;
                                    padding: 0.2rem 0;
                                }

                                &-serial-number {
                                    width: 24%;
                                    padding: 0.2rem 0;
                                }

                                &-year-manufactured {
                                    width: 20%;
                                }

                                &-created-at {
                                    width: 30%;
                                }
                            }
                        }
                    }

                    &-body {
                        position: relative;
                        margin-left: -0.5rem;
                        margin-right: -0.5rem;

                        &-space {
                            height: 0.5rem;
                        }

                        &-row-marked {
                            background-color: rgba(255, 248, 231, 0.8);
                        }

                        &-switch-on {
                            color: #007d00
                        }

                        &-switch-off {
                            color: #ab0000;
                            opacity: 0.15;
                        }

                        &-opacity {
                            opacity: 0.15;
                        }

                        &-topic {
                            &-title {
                                background-color: #F8F8FF;
                                color: #0C356A;
                                font-size: 0.9rem !important;
                                font-weight: bold;
                                font-family: "Open Sans";
                                text-align: left;
                                letter-spacing: 0.1rem;
                                padding: 0.1rem 0.5rem;
                                width: 100%;
                                height: 0, 1rem;
                            }

                            &-header {
                                background-color: #F8F8FF;
                                color: rgba(12, 52, 106, 0.5);
                                border-top: 1px solid #d9d8d6;
                                text-align: center;
                                font-size: 0.7rem !important;
                                font-family: "Open Sans";
                                font-weight: 600 !important;
                            }
                        }

                        &-flight,
                        &-fuel,
                        &-components,
                        &-calculus,
                        &-chart,
                        &-passenger,
                        &-floor-plan {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 0;
                            font-size: 0.95rem;
                            font-weight: bold;
                            letter-spacing: 0.1rem;
                            width: 100%;
                        }

                        &-flight {

                            &-data,
                            &-eobt,
                            &-eet,
                            &-logbook-stage,
                            &-logbook-in,
                            &-commander {
                                border: 2px solid #e5e4e2;
                                border-right: none;
                                border-top: none;
                                text-align: center;
                                padding: 0 0.5rem;
                                padding-top: 0.2rem;
                            }

                            &-data {
                                width: 26%;
                            }

                            &-eobt {
                                width: 18%;
                            }

                            &-eet {
                                width: 20%;
                            }

                            &-logbook {
                                &-stage,
                                &-in {
                                    width: 15%;
                                }
                                
                                // Isto tem que ser inserido apenas no segundo elemento
                                &-in {
                                    border-right: 2px solid #e5e4e2;
                                }
                            }
                        }

                        &-fuel {

                            &-time,
                            &-consumption {
                                border: 2px solid #e5e4e2;
                                border-right: none;
                                border-top: none;
                                text-align: center;
                                padding: 0 0.5rem
                            }

                            &-time {
                                width: 10%;
                            }

                            &-consumption {
                                width: 20%;

                                // Pegar o ultimo
                                &:last-child {
                                    border-right: 2px solid #e5e4e2;
                                }
                            }
                        }

                        &-components {

                            &-label,
                            &-weight,
                            &-floor-plan {
                                border: 2px solid #e5e4e2;
                                border-top: none;
                                padding: 0 0.5rem;
                            }

                            &-label {
                                width: 50%;
                                border-right: none;
                            }

                            &-weight {
                                width: 50%;
                                text-align: center;
                            }

                            &-floor-plan {
                                width: 100%;                                
                            }
                        }

                        &-calculus {

                            &-description,
                            &-weight,
                            &-moment,
                            &-arm {
                                border: 2px solid #e5e4e2;
                                border-top: none;
                                padding: 0 0.5rem;
                            }

                            &-description {
                                width: 23%;
                                border-right: none;

                                &-header {
                                    background-color: #F8F8FF;
                                }
                            }

                            &-weight {
                                width: 13%;
                                border-right: none;
                                text-align: center;
                            }

                            &-moment {
                                width: 20%;
                                border-right: none;
                                text-align: center;

                                &-last {
                                    border-right: 2px solid #e5e4e2;
                                }
                            }

                            &-arm {
                                width: 12%;
                                border-right: none;
                                text-align: center;
                            }

                            &-legend {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                color: #000;
                                width: 100%;
                                background-color: #dcdcdc;

                                &-item {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    font-size: 1rem;
                                    font-weight: 500;
                                    letter-spacing: 0.1rem;
                                    padding: 0.2rem 1rem;

                                    span {
                                        display: inline-block;
                                        margin-top: 0.1rem;
                                        margin-left: 0.2rem
                                    }

                                    &-ball {

                                        &-green,
                                        &-yellow,
                                        &-red {
                                            display: inline-block;
                                            border-radius: 20px;
                                            height: 1rem;
                                            width: 1rem;
                                        }

                                        &-green {
                                            background-color: rgba(0, 255, 0, 0.5);
                                            border: 2px solid #008d00;
                                        }

                                        &-yellow {
                                            background-color: rgba(255, 255, 0, 0.5);
                                            border: 2px solid #8d8d00;
                                        }

                                        &-red {
                                            background-color: rgba(255, 0, 0, 0.5);
                                            border: 2px solid #8d0000;
                                        }
                                    }
                                }
                            }
                        }

                        &-chart {
                            &-col-6-width {                              
                                width: 49.9% !important;                                
                            }

                            &-lateral,
                            &-longitudinal {
                                border: 2px solid #e5e4e2;
                                border-top: none;
                                padding: 0 0.5rem;
                                width:100%;
                                text-align: left;
                            }

                            &-frame {
                                padding: 1rem 1rem;
                                background-color: #fffaf0;

                                height: 27rem;
                            }

                            &-legend {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                color: #000;
                                width: 100%;
                                background-color: #dcdcdc;

                                &-item {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    font-size: 1rem;
                                    font-weight: 600;
                                    letter-spacing: 0.1rem;
                                    padding: 0.2rem 1rem;

                                    span {
                                        display: inline-block;
                                        margin-top: 0.1rem;
                                        margin-left: 0.2rem
                                    }

                                    &-ball {

                                        &-zfw,
                                        &-rw,
                                        &-tow,
                                        &-ldw {
                                            display: inline-block;
                                            border-radius: 20px;
                                            height: 1rem;
                                            width: 1rem;
                                        }

                                        &-zfw {
                                            background-color: #00b7eb;
                                        }

                                        &-rw {
                                            background-color: #000;
                                        }

                                        &-tow {
                                            background-color: #3cb371;
                                        }

                                        &-ldw {
                                            background-color: #dc143c;
                                        }
                                    }
                                }
                            }
                        }

                        &-passenger {

                            &-sequency,
                            &-document,
                            &-name,
                            &-phone-emergency {
                                border: 2px solid #e5e4e2;
                                border-right: none;
                                border-top: none;
                                padding: 0 0.5rem;
                                padding-top: 0.2rem;
                            }

                            &-sequency {
                                width: 8%;
                                text-align: center;
                            }

                            &-document {
                                width: 20%;
                            }

                            &-name {
                                width: 50%;
                            }

                            &-phone-emergency {
                                width: 22%;

                                border-right: 2px solid #e5e4e2;
                            }
                        }

                        &-floor-plan {
                            &-col-6-width {                              
                                width: 49.9% !important;                                
                            }

                            &-frame {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 2px solid #e5e4e2;
                                border-top: none;
                                padding: 0 0.5rem;
                                padding-top: 1rem;
                                width: 100%;
                                height: 25rem;
                            }

                            &-header {
                                border: 2px solid #e5e4e2;
                                border-top: none;
                                padding: 0 0.5rem;
                                width: 100%;
                                text-align: left;
                            }
                        }

                        &-commander {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            border-top: 2px solid #000;
                            width: 100%;


                            &-responsible {
                                width: 40%;
                                border-right: 2px solid #000;
                                padding: 0.2rem 0.5rem;

                                &-label {
                                    color: #0C356A;
                                    font-size: 0.9rem !important;
                                    font-weight: bold;
                                    font-family: "Open Sans";
                                }

                                &-content {
                                    font-size: 0.9rem;
                                }
                            }

                            &-approved {
                                width: 40%;
                                border-right: 2px solid #000;
                                padding: 0.2rem 0.5rem;

                                &-label {
                                    color: #0C356A;
                                    font-size: 0.9rem !important;
                                    font-weight: bold;
                                    font-family: "Open Sans";
                                }

                                &-content {
                                    font-size: 0.9rem;
                                }
                            }

                            &-signature {
                                width: 20%;
                                padding: 0.2rem 0.5rem;

                                &-label {
                                    color: #0C356A;
                                    font-size: 0.9rem !important;
                                    font-weight: bold;
                                    font-family: "Open Sans";
                                }
                            }
                        }
                    }
                }

                /*&-preview {
                    width: 210mm;
                    height: 297mm;
                    padding: 2mm;
                    margin: 2mm;
                    border: 2px solid #000;
                    

                    &-space {
                        height: 1rem;
                    }

                    &-row-marked {
                        background-color: #f4f0ec;
                    }

                    &-switch-on {
                        color: #007d00
                    }

                    &-switch-off {
                        color: #ab0000
                    }

                    &-topic-title {
                        background-color: #0C356A;
                        color: #FFF;
                        font-size: 1rem;
                        font-weight: bold;
                        text-align: center;
                        width: 100%;
                    }

                    &-top {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        background-color: #0C356A;
                        height: 3rem;
                        padding: 0 1rem;
                        margin-top: -2mm;
                        margin-left: -2mm;
                        margin-right: -2mm;

                        &-image {
                            flex-grow: 0;

                            img {
                                display: block;
                                width: 8rem;
                            }
                        }

                        &-title {
                            flex-grow: 1;
                            font-size: 1.5rem;
                            color: #FFF;
                            margin-left: 0.5rem;
                            text-align: center;
                            font-weight: 600;
                            letter-spacing: 0.1rem;
                        }
                    }

                    &-header {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        &-image {
                            img {
                                display: block;
                                width: 7rem;
                                height: 5rem;
                            }
                        }

                        &-content {
                            background-color: #dcdcdc;
                            width: 100%;
                            padding: 0.1rem;
                            font-weight: bold;

                            &-row {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                padding: 0 0.5rem;
                                font-size: 1.2rem;
                                color: #334155;

                                &-mark {
                                    width: 25%;
                                    //border: 1px solid #000;
                                    padding: 0.2rem 0;
                                }

                                &-sheet-name {
                                    width: 75%;
                                    //border: 1px solid #FF0;
                                    padding: 0.2rem 0;
                                }

                                &-icao-code {
                                    width: 25%;
                                    //border: 1px solid #F00;
                                    padding: 0.2rem 0;
                                }

                                &-serial-number {
                                    width: 25%;
                                    //border: 1px solid #0F0;
                                    padding: 0.2rem 0;
                                }

                                &-year-manufactured {
                                    width: 25%;
                                    //border: 1px solid #00F;
                                }

                                &-created-at {
                                    width: 25%;
                                    //border: 1px solid #0FF;
                                }
                            }
                        }
                    }

                    &-flight{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 0;
                        font-family: "Signika";
                        font-size: 1rem;
                        font-weight: 500;
                        letter-spacing: 0.1rem;
                        width: 100%;

                        &-header {
                            background-color: #dcdcdc;
                            border-top: 2px solid #e5e4e2;
                            text-align: center;
                            font-size: 1rem;
                            font-weight: bold;
                        }

                        &-data{
                            border: 2px solid #e5e4e2;
                            border-right: none;
                            border-top: none;
                            width: 8%;
                            text-align: center;
                            padding: 0 0.5rem;

                            // Pegar o ultimo
                            &:last-child {
                                border-right: 2px solid #e5e4e2;
                            }
                        }

                        &-eobt{
                            border: 2px solid #e5e4e2;
                            border-right: none;
                            border-top: none;
                            width: 18%;
                            text-align: center;
                            padding: 0 0.5rem
                        }

                        &-eet{
                            border: 2px solid #e5e4e2;
                            border-right: none;
                            border-top: none;
                            width: 20%;
                            text-align: center;
                            padding: 0 0.5rem
                        }

                        &-logbook{
                            border: 2px solid #e5e4e2;
                            border-right: none;
                            border-top: none;
                            width: 15%;
                            text-align: center;
                            padding: 0 0.5rem
                        }

                        &-commander{
                            border: 2px solid #e5e4e2;
                            border-top: none;
                            width: 40%;
                            text-align: center;
                            padding: 0 0.5rem
                        }
                    }

                    &-fuel {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 0;
                        font-family: "Signika";
                        font-size: 1rem;
                        font-weight: 500;
                        letter-spacing: 0.1rem;
                        width: 100%;

                        &-header {
                            background-color: #dcdcdc;
                            border-top: 2px solid #e5e4e2;
                            text-align: center;
                            font-size: 1rem;
                            font-weight: bold;
                        }

                        &-time {
                            border: 2px solid #e5e4e2;
                            border-right: none;
                            border-top: none;
                            width: 10%;
                            text-align: center;
                            padding: 0 0.5rem
                        }

                        &-consumption {
                            border: 2px solid #e5e4e2;
                            border-right: none;
                            border-top: none;
                            width: 20%;
                            text-align: center;
                            padding: 0 0.5rem;

                            // Pegar o ultimo
                            &:last-child {
                                border-right: 2px solid #e5e4e2;
                            }
                        }
                    }

                    &-calculus {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 0;
                        font-family: "Signika";
                        font-size: 1.1rem;
                        font-weight: 500;
                        letter-spacing: 0.1rem;
                        width: 100%;

                        &-header {
                            background-color: #dcdcdc;
                            border-top: 2px solid #e5e4e2;
                            text-align: center;
                            font-size: 1.1rem;
                            font-weight: bold;
                        }

                        &-label {
                            border: 2px solid #e5e4e2;
                            border-right: none;
                            border-top: none;
                            width: 21%;
                            padding: 0 0.5rem;

                            &-header {
                                background-color: #dcdcdc;
                                border-top: 2px solid #e5e4e2;
                            }
                        }

                        &-weight {
                            border: 2px solid #e5e4e2;
                            border-right: none;
                            border-top: none;
                            width: 13%;
                            text-align: center;
                            padding: 0 0.5rem;
                        }

                        &-long-arm {
                            border: 2px solid #e5e4e2;
                            border-right: none;
                            border-top: none;
                            width: 13%;
                            text-align: center;
                            padding: 0 0.5rem;
                        }

                        &-long-moment {
                            border: 2px solid #e5e4e2;
                            border-right: none;
                            border-top: none;
                            width: 20%;
                            text-align: center;
                            padding: 0 0.5rem;
                        }

                        &-lateral-arm {
                            border: 2px solid #e5e4e2;
                            border-right: none;
                            border-top: none;
                            width: 13%;
                            text-align: center;
                            padding: 0 0.5rem;
                        }

                        &-lateral-moment {
                            border: 2px solid #e5e4e2;
                            border-right: none;
                            border-top: none;
                            width: 20%;
                            text-align: center;
                            padding: 0 0.5rem;

                            // Pegar o ultimo
                            &:last-child {
                                border-right: 2px solid #e5e4e2;
                            }
                        }

                        &-separator {
                            width: 100%;
                            background-color: #777696;
                            height: 1rem;
                        }
                    }

                    &-calculation{
                        &-legend {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            color: #000;
                            width: 100%;
                            background-color: #dcdcdc;
    
                            &-item {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                font-size: 1rem;
                                font-weight: 500;
                                letter-spacing: 0.1rem;
                                padding: 0.2rem 1rem;
    
                                span {
                                    display: inline-block;
                                    margin-top: 0.1rem;
                                    margin-left: 0.2rem
                                }
    
                                &-ball {
                                    &-green {
                                        display: inline-block;
                                        background-color: rgba(0, 255, 0, 0.5);
                                        border: 2px solid #008d00;
                                        border-radius: 20px;
                                        height: 1rem;
                                        width: 1rem;
                                    }
    
                                    &-yellow {
                                        display: inline-block;
                                        background-color: rgba(255, 255, 0, 0.5);
                                        border: 2px solid #8d8d00;
                                        border-radius: 20px;
                                        height: 1rem;
                                        width: 1rem;
                                    }
    
                                    &-red {
                                        display: inline-block;
                                        background-color: rgba(255, 0, 0, 0.5);
                                        border: 2px solid #8d0000;
                                        border-radius: 20px;
                                        height: 1rem;
                                        width: 1rem;
                                    }
                                }
                            }
                        }
                    }

                    &-charter {
                        &-legend {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            color: #000;
                            width: 100%;
                            background-color: #dcdcdc;
                            margin-top: 1rem;
    
                            &-item {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                font-size: 1rem;
                                font-weight: 600;
                                letter-spacing: 0.1rem;
                                padding: 0.2rem 1rem;
    
                                span {
                                    display: inline-block;
                                    margin-top: 0.1rem;
                                    margin-left: 0.2rem
                                }
    
                                &-ball {
                                    &-zfw {
                                        display: inline-block;
                                        background-color: #00b7eb;
                                        border-radius: 20px;
                                        height: 1rem;
                                        width: 1rem;
                                    }
    
                                    &-rw {
                                        display: inline-block;
                                        background-color: #000;
                                        border-radius: 20px;
                                        height: 1rem;
                                        width: 1rem;
                                    }
    
                                    &-tow {
                                        display: inline-block;
                                        background-color: #3cb371;
                                        border-radius: 20px;
                                        height: 1rem;
                                        width: 1rem;
                                    }
    
                                    &-ldw {
                                        display: inline-block;
                                        background-color: #dc143c;
                                        border-radius: 20px;
                                        height: 1rem;
                                        width: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }*/

                &-charter {
                    &-legend {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        color: #000;
                        width: 100%;
                        background-color: #dcdcdc;
                        margin-top: 1rem;

                        &-item {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            font-size: 1.2rem;
                            font-weight: 500;
                            letter-spacing: 0.1rem;
                            padding: 0.5rem 1rem;

                            span {
                                display: inline-block;
                                margin-top: 0.1rem;
                                margin-left: 0.2rem
                            }

                            &-ball {
                                &-zfw {
                                    display: inline-block;
                                    background-color: #00b7eb;
                                    border-radius: 20px;
                                    height: 1.2rem;
                                    width: 1.2rem;
                                }

                                &-rw {
                                    display: inline-block;
                                    background-color: #000;
                                    border-radius: 20px;
                                    height: 1.2rem;
                                    width: 1.2rem;
                                }

                                &-tow {
                                    display: inline-block;
                                    background-color: #3cb371;
                                    border-radius: 20px;
                                    height: 1.2rem;
                                    width: 1.2rem;
                                }

                                &-ldw {
                                    display: inline-block;
                                    background-color: #dc143c;
                                    border-radius: 20px;
                                    height: 1.2rem;
                                    width: 1.2rem;
                                }
                            }
                        }
                    }
                }

                &-calculation {
                    .p-datatable-tbody {
                        tr {
                            td {
                                font-size: 1.5rem;
                            }
                        }
                    }

                    .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
                        background: #f4f0ec;
                    }

                    &-theader {
                        th {
                            background-color: #367588;
                            color: #FFF;
                            font-size: 1rem;
                            font-weight: bold;
                            letter-spacing: 0.1rem;
                            padding: 0.5rem 1rem 0.3rem;
                            //font-family: "Nunito Sans"; 
                            //font-family: "Hanken Grotesk";
                            //font-family: "Aleo";
                            //font-family: "Montserrat";
                            //font-family: "Poppins";
                            //font-family: "Rubik";
                            font-family: "Roboto";
                        }

                        &-label {
                            text-transform: uppercase;
                            font-size: 1.1rem;
                            line-height: 1.1;
                        }


                    }

                    &-body {
                        td {
                            font-size: 1.4rem !important;
                            font-family: "Roboto";

                            padding: 0.3rem 1rem;
                        }

                        &-item {
                            &-label {
                                font-family: "Roboto";
                                font-size: 1.3rem;
                                font-weight: bold;

                                span {
                                    margin-left: 0.3rem;
                                }
                            }
                        }
                    }

                    &-legend {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        color: #000;
                        width: 100%;
                        background-color: #dcdcdc;

                        &-item {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            font-size: 1.2rem;
                            font-weight: 500;
                            letter-spacing: 0.1rem;
                            padding: 0.5rem 1rem;

                            span {
                                display: inline-block;
                                margin-top: 0.1rem;
                                margin-left: 0.2rem
                            }

                            &-ball {
                                &-green {
                                    display: inline-block;
                                    background-color: rgba(0, 255, 0, 0.5);
                                    border: 2px solid #008d00;
                                    border-radius: 20px;
                                    height: 1.2rem;
                                    width: 1.2rem;
                                }

                                &-yellow {
                                    display: inline-block;
                                    background-color: rgba(255, 255, 0, 0.5);
                                    border: 2px solid #8d8d00;
                                    border-radius: 20px;
                                    height: 1.2rem;
                                    width: 1.2rem;
                                }

                                &-red {
                                    display: inline-block;
                                    background-color: rgba(255, 0, 0, 0.5);
                                    border: 2px solid #8d0000;
                                    border-radius: 20px;
                                    height: 1.2rem;
                                    width: 1.2rem;
                                }
                            }
                        }
                    }
                }

                &-input {
                    &-table {
                        &-theader {
                            th {
                                padding: 0;
                                border-top: 0;
                            }
                        }

                        &-td {
                            &-field {
                                .p-inputtext {
                                    padding: 0.3rem 0.7rem;
                                    font-size: 1.1rem;
                                    line-height: 1;
                                    font-weight: 500;
                                    font-weight: 600;
                                }

                                padding: 0.5rem 0.5rem 0;

                                &-opposite-unit {
                                    width: 100%;
                                    font-size: 0.8rem;
                                    font-weight: bold;
                                    text-align: right;
                                    //font-family: "Nunito Sans"; 
                                    //font-family: "Hanken Grotesk";
                                    //font-family: "Aleo";
                                    //font-family: "Montserrat";
                                    //font-family: "Poppins";
                                    //font-family: "Rubik";
                                    font-family: "Roboto";
                                }
                            }

                            &-switch {
                                padding-top: 1.2rem;
                                padding-bottom: 1.2rem;

                                .p-inputswitch .p-inputswitch-slider {
                                    background: #dc143c;
                                }

                                .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
                                    background: #2a8000;
                                }
                            }

                            &-complement {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 0.5rem 1rem;

                                &-pax {}

                                &-action {
                                    p-button {
                                        margin-left: 0.3rem;
                                    }
                                }
                            }
                        }
                    }

                    &-fuel {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        width: 100%;
                        min-height: 17rem;

                        &-info {
                            border: 1px solid #e2e8f0;
                            width: 100%;
                            padding: 1rem;
                            height: 100%;

                            &-data {
                                font-size: 1.2rem;
                            }

                            &-gap {
                                height: 1rem;
                            }

                            &-density {}
                        }

                        &-fields {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #e2e8f0;
                            width: 100%;
                            height: 100%;

                            padding: 1rem;

                            &-label {}

                            &-knob {
                                position: relative;
                                text-align: center;
                                width: 100%;


                                .p-knob-text {
                                    font-weight: bold;
                                    font-family: "Roboto";
                                    font-size: 2rem !important;
                                }

                                &-buttons {
                                    &-left {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        flex-direction: column;

                                        position: absolute;
                                        top: 0;
                                        left: 0;

                                        button {
                                            padding: 0;
                                            width: 3.5rem;
                                            padding: 0.2rem 00.1rem;
                                            margin-bottom: 0.3rem;
                                            font-family: "Roboto";
                                            font-size: 0.9rem;
                                            font-weight: 800;
                                            letter-spacing: 0.1rem;
                                        }
                                    }

                                    &-right {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        flex-direction: column;

                                        position: absolute;
                                        top: 0;
                                        right: 0;

                                        button {
                                            padding: 0;
                                            width: 3.5rem;
                                            padding: 0.2rem 00.1rem;
                                            margin-bottom: 0.3rem;
                                            font-family: "Roboto";
                                            font-size: 0.9rem;
                                            font-weight: 800;
                                            letter-spacing: 0.1rem;
                                        }
                                    }
                                }
                            }

                            &-text {}
                        }

                        &-others-unit {
                            border: 1px solid #e2e8f0;
                            width: 100%;
                            height: 100%;
                            padding: 1rem;

                            &-itens {
                                font-size: 1.2rem;
                                font-weight: 500;
                                font-family: "Roboto";

                                &-alert {
                                    font-size: 1.3rem;
                                    font-weight: 500;
                                    font-family: "Roboto";
                                    width: 100%;
                                    padding: 0.3rem 1rem;

                                    background: rgba(245, 150, 150, 0.499);
                                    border: 2px solid #8d0000;
                                }
                            }
                        }
                    }
                }

                &-alert {
                    &-danger {
                        background-color: rgba(255, 0, 0, 0.5);
                        border: 2px solid #8d0000;
                        color: #FFF;
                        font-weight: bold;
                        margin: 0rem -1rem;
                    }

                    &-warning {
                        background-color: rgba(255, 255, 0, 0.5);
                        border: 2px solid #8d8d00;
                        color: #000;
                        font-weight: bold;
                        margin: 0rem -1rem;
                    }

                    &-ok {
                        background-color: rgba(0, 255, 0, 0.5);
                        border: 2px solid #008d00;
                        color: #000;
                        font-weight: bold;
                        margin: 0rem -1rem;
                    }

                    &-preview {
                        margin: 0rem -0.5rem;
                    }
                }
            }

            &-floor-plan {
                img {
                    width: 20%
                }
            }

            &-aerodrome-input-fieldset {
                margin-top: 1rem;
                margin-bottom: 0.5rem;

                .p-fieldset .p-fieldset-legend span {
                    padding: 0 0.5rem;
                }

                .cls-ui-primeng-field-only-label {
                    margin-top: -0.5rem;
                }

                &-header {
                    font-family: var(--font-family-menu);
                    display: flex;
                    align-items: center;
                    font-weight: normal;

                    &-image {
                        img {
                            display: block;
                            width: 2.5rem;
                        }
                    }

                    &-label {
                        display: block;
                        margin-left: -0.5rem;
                    }
                }

                &-sub-label {
                    margin-top: 0.2rem;
                }

                &-content {
                    display: flex;

                    &-input {
                        flex-grow: 1;
                    }

                    &-button {
                        flex-grow: 0;
                        margin-left: 0.4rem;
                        //width: 3.5rem;
                    }
                }
            }

            &-aircraft-info-page {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                background-color: #0C356A;
                margin-bottom: 1rem;
                padding: 1rem;
                height: 100%;
                width: 100%;

                &-image {
                    text-align: center;
                    width: 100%;
                    max-height: 15rem;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: fill; // ou 'contain', 'fill', 'scale-down', dependendo do comportamento desejado
                    }
                }

                &-content {
                    color: #0C356A;
                    margin-top: 1.1rem;
                    width: 100%;

                    .grid {
                        background-color: #FFF;
                    }

                    &-box {
                        border: 3px solid #0C356A;
                        margin: -0.5rem -0.6rem 0.4rem -0.6rem;
                        padding: 0.2rem 0.5rem;

                        @media (max-width: 599px) {
                            margin: -0.5rem -0.6rem 0 -0.6rem;
                        }

                        @media (min-width: 600px) and (max-width: 959px) {
                            margin: -0.5rem -0.6rem 0 -0.6rem;
                        }
                    }

                    &-box-last {
                        border: 3px solid #0C356A;
                        margin: -0.5rem -0.6rem -0.5rem -0.6rem;
                        padding: 0.2rem 0.5rem;

                        @media (max-width: 599px) {
                            margin: -0.5rem -0.6rem -0.6rem -0.6rem;
                        }

                        @media (min-width: 600px) and (max-width: 959px) {
                            margin: -0.5rem -0.6rem -0.6rem -0.6rem;
                        }
                    }

                    &-title {
                        font-family: 'Bruno Ace SC';
                        font-size: 0.8rem;
                        text-transform: uppercase;
                        letter-spacing: 0.1rem;
                    }

                    &-value {
                        font-family: 'Bruno Ace SC';
                        font-size: 1rem;
                        font-weight: 800;
                        height: 1.8rem;
                        letter-spacing: 0.1rem;
                        overflow: hidden;
                    }
                }
            }

            &-unit-conversion {
                &-card {
                    font-size: 1.5rem;
                    color: #FFF;
                    width: 30rem;
                    padding: 1rem 1.5rem;
                    background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(0, 152, 155, 1) 0.1%, rgba(0, 94, 120, 1) 94.2%);
                    border-radius: 12px;

                    label {
                        color: #FFF;
                        text-transform: uppercase;
                        letter-spacing: 0.1rem;
                    }

                    &-header {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.8rem;
                        font-family: 'Racing Sans One';
                        color: #ffe4b5;
                        padding: 0.2rem 0;
                        font-family: 'Racing Sans One';
                        letter-spacing: 0.1rem;
                        text-transform: uppercase;

                        span {
                            margin-left: 0.5rem;
                        }
                    }

                    &-content {
                        &-dropdown {
                            margin-top: -0.2rem;
                        }

                        &-result {
                            .cls-ui-primeng-field {
                                div {
                                    background-color: #d1e189;
                                    color: #000;
                                }
                            }
                        }
                    }

                    &-toolbar {
                        .p-toolbar {
                            text-align: center;
                            background-color: transparent;
                        }
                    }
                }
            }

            &-time-zone {
                //display: flex;
                //align-items: flex-start;
                //justify-content: flex-start;
                //font-size: 1.1rem;
                //color: #0C356A;
                //margin-top: 0.5rem;
                border: 2px solid #e2e8f0;
                padding: 0.1rem 0.8rem;
                border-radius: 0.8rem;

                &-header {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 1.1rem;
                    font-family: 'Racing Sans One';
                    letter-spacing: 0.1rem;
                    //color: #000;
                    //padding: 0.2rem 1rem;
                    //
                    //font-family: 'Racing Sans One';
                    //letter-spacing: 0.1rem;
                    //text-transform: uppercase;

                    &-icon {
                        margin-right: 0.3rem;
                    }

                    &-title {
                        margin-top: 0.1rem;
                        overflow-x: hidden;
                    }
                }

                &-content {
                    &-date {
                        letter-spacing: 0.1rem;
                        font-family: Roboto;
                    }

                    &-time {
                        font-size: 1.1rem;
                        font-weight: bold;
                        color: #0C356A;
                        letter-spacing: 0.1rem;
                        font-family: Roboto;
                    }
                }
            }

            &-top-header {
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: space-between;
                color: #FFF;
                height: 100%;

                &-left {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                &-center {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &-right {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    height: 100%;
                }

                &-frame-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 1rem;
                    height: 100%;
                }

                &-flag {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }

                &-button {
                    position: relative;
                    padding: 0.2rem 1rem;

                    .p-button-icon {
                        color: #FFF;
                        font-size: 1.7rem;
                    }

                    .p-badge {
                        position: absolute;
                        background-color: #FF0000;
                        color: #FFF;
                        min-width: 1.5rem;
                        height: 1.5rem;
                        line-height: 1.5rem;
                        top: -0.6rem;
                        font-size: 1rem;
                        padding: 0 0.3rem 0 0.3rem;
                    }
                }

                &-user {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    height: 100%;
                    background-color: rgba(255, 255, 255, 0.2);
                    padding-left: 1rem;
                    padding-right: 1rem;
                    margin-left: 0.5rem;

                    &-button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        color: #FFF;

                        &:hover {
                            background-color: rgba(255, 255, 255, 0.3);
                        }

                        .p-avatar {
                            margin-right: 0.5rem;
                        }


                    }
                }
            }

            &-full-calendar {
                full-calendar {
                    max-height: 60rem;
                }
            }

            &-upload {
                &-dropzone {
                    position: relative;
                    background-color: #FFF;
                    border: dotted 2px #0C356A;
                    width: 100%;
                    z-index: 1;

                    &-progress {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba(0, 0, 0, 0.5);
                        color: #FFF;
                        font-size: 1rem;
                        letter-spacing: 0.1rem;
                        text-align: center;

                        position: absolute;
                        z-index: 100;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        padding: 0.5rem;

                        p-progressbar {
                            width: 100%;
                            margin-top: 0.5rem;
                            height: 1rem;
                        }
                    }

                    &-box {
                        &-upload {
                            display: flex;
                            align-items: center;
                            position: relative;
                            width: 100%;
                            height: 100%;
                            min-height: 12rem;

                            &-watermark {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                pointer-events: none;

                                /* Permite que os elementos abaixo sejam clicáveis */
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                    opacity: 0.07;
                                    /* Ajuste a opacidade conforme necessário */
                                }
                            }

                            &-info {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: center;

                                height: 100%;
                                width: 100%;

                                &-icon {
                                    font-size: 3rem;
                                    color: #0C356A;
                                }

                                &-label {
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                    color: #0C356A;
                                    position: relative;
                                    z-index: 1;
                                    /* Garante que o texto esteja acima da marca d'água */
                                    text-align: center;
                                }

                                &-button {
                                    margin-top: 1rem;
                                    margin-bottom: 0.5rem;

                                    button {
                                        font-size: 1rem;
                                        font-weight: bold;
                                        padding: 0.5rem 1rem;
                                        text-transform: uppercase;
                                    }
                                }

                                &-advice {
                                    text-align: center;
                                    font-size: 0.9rem;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }

                &-show-image {
                    position: relative;

                    &-frame {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        width: 100%;
                        border: 2px solid #D8D8D8;
                        border-radius: 1rem;
                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                        margin-bottom: 1rem;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 1rem;
                            margin-bottom: -0.5rem;
                        }
                    }

                    &-button {
                        position: absolute;
                        top: 1rem;
                        right: 1rem;
                    }
                }

                /*margin-bottom: 1rem;                

                &-show-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;
                    border: 1px solid #D8D8D8;
                    border-radius: 1rem;
                    background-color: #FFF;
                    box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 1rem;
                    }
                }*/

            }

            &-handler {
                &-address {}

                &-contact {}
            }

            &-info-box {
                &-default {
                    position: relative;
                    border: 1px solid #D8D8D8;
                    border-radius: 1rem;
                    padding: 1rem;
                    background-color: #FFF;
                    box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
                    min-height: 7.5rem;

                    &-link {
                        position: absolute;
                        top: 0;
                        right: 0.5rem;
                    }

                    &-box {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        height: 100%;

                        &-icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            border: 1px solid #D8D8D8;
                            font-size: 3rem;
                            margin-right: 1rem;
                            padding: 1.2rem 1.2rem;
                            border-radius: 0.5rem;
                            color: rgba(255, 255, 255, .6);

                            i {
                                margin: 0 auto;
                            }
                        }

                        &-content {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            height: 100%;
                            width: 100%;

                            &-label {
                                h2 {
                                    font-size: 1.1rem;
                                    margin: 0;
                                    padding: 0;
                                    text-transform: uppercase;
                                }
                            }

                            &-value {
                                font-size: 2rem;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            &-title-page {
                display: flex;
                align-items: flex-start;
                padding-bottom: 0.5rem;
                font-family: "Rubik Medium", sans-serif;
                letter-spacing: 0.1rem;
                border-bottom: 2px solid #777;
                margin-top: 1rem;
                margin-bottom: 1rem;

                &-icon {
                    font-size: 2.2rem;
                    margin-right: 0.5rem;

                    @media (max-width: 767px) {
                        font-size: 1.5rem;
                        line-height: 1.5rem;
                    }
                }

                &-content {
                    h1 {
                        font-size: 1.5rem;
                        margin: 0;
                        padding: 0;
                        text-transform: uppercase;

                        @media (max-width: 767px) {
                            font-size: 1.5rem;
                            line-height: 1.6rem;
                        }
                    }

                    &-subtitle {
                        font-size: 1.1rem;
                        margin-top: -0.8rem;

                        @media (max-width: 767px) {
                            display: none;
                        }
                    }
                }
            }

            &-login {
                &-input {
                    position: relative;
                    margin-bottom: 1rem;

                    label {
                        color: #4A5568;
                        font-size: 1.1rem;
                        text-transform: uppercase;
                    }

                    input {
                        font-size: 1.4rem;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                    }
                }

                &-password-forgot {
                    position: absolute;
                    color: #00A86B;

                    font-size: 1.2rem;
                    right: 0.3rem;
                    top: -0.1rem;

                    a {
                        text-decoration: none;
                    }
                }

                &-button {
                    button {
                        display: block;
                        width: 100%;
                        text-align: center;
                        font-size: 1.23rem;
                        font-weight: bold;
                        margin-top: 2.5rem;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        text-transform: uppercase;
                    }
                }

                &-sign-up {
                    text-align: center;
                    margin-top: 2rem;
                    font-size: 1.4rem;

                    a {
                        color: #00A86B;
                        text-decoration: none;
                    }
                }

                &-error {
                    font-family: 'Roboto', Courier, monospace;
                    background-color: #f6c7c7;
                    color: rgb(93, 11, 11);
                    font-size: 1.5rem;
                    font-weight: 400;
                    padding: 1rem;
                    letter-spacing: 0.1rem;
                }
            }
        }
    }
}