.cls-animation {
    &-loading {

        //https://css-loaders.com/
        &-classic {
            &-01 {
                /* <div class="cls-animation-loading-classic-01">TEXTO QUALQUER</div> */
                animation: l1 1.5s linear infinite alternate;

                @keyframes l1 {
                    to {
                        opacity: 0.2
                    }
                }
            }
        }

        &-line {

            /* HTML: <div class="loader"></div> */
            &-01 {
                width: 50px;
                aspect-ratio: 1;
                display: flex;

                &::before,
                &::after {
                    content: "";
                    flex: 1;
                    margin: 0 0 0 -2px;
                    --c: no-repeat linear-gradient(#f03355 0 0);
                    background:
                        var(--c) 0 0,
                        var(--c) 100% 0,
                        var(--c) 100% 100%,
                        var(--c) 0 100%,
                        var(--c) 0 50%;
                    animation: l17 1s infinite alternate;
                }

                &::before {
                    transform: scale(-1);
                    margin: 0 -2px 0 0;
                }

                @keyframes l17 {

                    0%,
                    5% {
                        background-size: 0 4px, 4px 0, 0 4px, 4px 0, 0 4px
                    }

                    20% {
                        background-size: 100% 4px, 4px 0, 0 4px, 4px 0, 0 4px
                    }

                    40% {
                        background-size: 100% 4px, 4px 100%, 0 4px, 4px 0, 0 4px
                    }

                    60% {
                        background-size: 100% 4px, 4px 100%, 100% 4px, 4px 0, 0 4px
                    }

                    80% {
                        background-size: 100% 4px, 4px 100%, 100% 4px, 4px 50%, 0 4px
                    }

                    95%,
                    100% {
                        background-size: 100% 4px, 4px 100%, 100% 4px, 4px 50%, 100% 4px
                    }
                }
            }
        }

        &-shapes {
            &-01 {
                /* HTML: <div class="cls-animation-loading-shapes-01"></div> */
                width: 6rem;
                aspect-ratio: 1;
                color: #f03355;
                position: relative;
                background:
                    conic-gradient(from 134deg at top, currentColor 92deg, #0000 0) top,
                    conic-gradient(from -46deg at bottom, currentColor 92deg, #0000 0) bottom;
                background-size: 100% 50%;
                background-repeat: no-repeat;
                margin-top: 1rem;
                margin-bottom: 2rem;

                &::before {
                    content: '';
                    position: absolute;
                    inset: 0;
                    --g: currentColor 14.5px, #0000 0 calc(100% - 14.5px), currentColor 0;
                    background:
                        linear-gradient(45deg, var(--g)),
                        linear-gradient(-45deg, var(--g));
                    animation: l7 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
                }

                @keyframes l7 {
                    33% {
                        inset: -10px;
                        transform: rotate(0deg)
                    }

                    66% {
                        inset: -10px;
                        transform: rotate(90deg)
                    }

                    100% {
                        inset: 0;
                        transform: rotate(90deg)
                    }
                }
            }
        }

        &-spinner {
            &-01 {
                /* HTML: <div class="cls-animation-loading-spinner-01"></div> */
                width: 7rem;
                aspect-ratio: 1;
                display: grid;
                border: 4px solid #0000;
                border-radius: 50%;
                border-color: #ccc #0000;
                animation: l16 1s infinite linear;

                &::before,
                &::after {
                    content: "";
                    grid-area: 1/1;
                    margin: 2px;
                    border: inherit;
                    border-radius: 50%;
                }

                &::before {
                    border-color: #f03355 #0000;
                    animation: inherit;
                    animation-duration: .5s;
                    animation-direction: reverse;
                }

                &::after {
                    margin: 8px;
                }

                @keyframes l16 {
                    100% {
                        transform: rotate(1turn)
                    }
                }
            }
        }
    }
}