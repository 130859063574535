[data-frame="app-platform-frame-dashboard-horizontal"] {
    .cls-frame {
        &-dashboard-horizontal {
            height: 100%;
            background-color: #F3F3F9;

            &-grid {
                display: grid;
                grid-template-rows: 5rem 3.1rem auto 3rem;
                grid-template-columns: 1fr;
                grid-template-areas: "header" "nav" "main" "footer";
                height: 100%;
            }         

            &-header {
                grid-area: header;
                background: #0c356a;
            }

            &-nav {
                grid-area: nav;
                background-color: #e5e4e2;


                .p-menubar {
                    background: transparent;
                    color: #0c356a;
                    border: 1px solid #e2e8f0;
                    font-size: 1.2rem;
                    font-family: "Nunito Sans Medium", sans-serif;
                    font-weight: bold;
                    letter-spacing: 0.08rem;
                }

                .p-menubar .p-menubar-root-list {
                    outline: 0 none;
                }

                .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content {
                    color: #334155;
                    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
                    border-radius: 6px;
                }

                .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link {
                    padding: 0.3rem 0.75rem;
                    user-select: none;
                }

                .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
                    color: #0c356a;
                }

                .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
                    color: #0c356a;
                    margin-right: 0.5rem;
                }

                .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
                    color: #0c356a;
                    margin-left: 0.5rem;
                }

                .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
                    color: #0c356a;
                }

                .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
                    color: #0c356a;
                }

                .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
                .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
                    color: #0c356a;
                }

                .p-menubar .p-menuitem>.p-menuitem-content {
                    color: #0c356a;
                }

                .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link {
                    color: #0c356a;
                }

                .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
                    color: #0c356a;
                }

                .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
                    color: #0c356a;
                    margin-right: 0.5rem;
                }

                .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
                    color: #0c356a;
                }

                .p-menubar .p-menuitem.p-highlight>.p-menuitem-content {
                    color: #1D4ED8;
                }

                .p-menubar .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
                    color: #0c356a;
                }

                .p-menubar .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
                .p-menubar .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
                    color: #1D4ED8;
                }

                .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
                    color: #0c356a;
                }

                .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
                    color: #0c356a;
                }

                .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
                .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
                    color: #0c356a;
                }

                .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
                    color: #0c356a;
                }

                .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
                    color: #0c356a;
                }

                .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
                .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
                    color: #0c356a;
                }

                .p-menubar .p-submenu-list {
                    padding: 0.8rem 0.3rem;
                    width: auto;

                    min-width: 15rem;
                }
            }

            &-main {
                grid-area: main;
                height: 100%;                                               
                font-size: 1.4rem !important;
                font-family: "Roboto Medium", sans-serif;
                overflow-y: scroll;
                
                &-form-grid-3-col {
                    display: grid;
                    grid-template-rows: 1fr;
                    grid-template-columns: 25rem auto 25rem;
                    grid-template-areas: "left content right";
                    height: 100%;                    

                    /* Telas grandes (desktops, 960px a 1279px) */
                    @media (min-width: 960px) and (max-width: 1279px) {
                        grid-template-columns: 22rem auto 15rem;
                    }

                    /* Telas extra grandes (desktops grandes, > 1440px) */
                    @media (min-width: 1441px) {
                        grid-template-columns: 22rem auto 15rem;
                    }

                    // Para Telas médias e pequenas (tablets, smartphones, < 960px)
                    @media (max-width: 960px) {
                        grid-template-columns: 1fr;
                        grid-template-rows: 8rem auto 8rem;
                        grid-template-areas: 
                            "left"
                            "content"
                            "right";
                    }

                    // Para Telas muito pequenas (smartphones, < 600px)
                    @media (max-width: 600px) {
                        grid-template-columns: 1fr;
                        grid-template-rows: auto;
                        grid-template-areas: 
                            "left"
                            "content"
                            "right";
                    }
                }

                &-form-grid-2-col-right{
                    display: grid;
                    grid-template-rows: 1fr;
                    grid-template-columns: auto 25rem;
                    grid-template-areas: "content right";
                    height: 100%;

                    /* Telas grandes (desktops, 960px a 1279px) */
                    @media (min-width: 960px) and (max-width: 1279px) {
                        grid-template-columns: auto 20rem;
                    }

                    /* Telas extra grandes (desktops grandes, > 1440px) */
                    @media (min-width: 1441px) {
                        grid-template-columns: auto 25rem;
                    }

                    // Para Telas médias e pequenas (tablets, smartphones, < 960px)
                    @media (max-width: 960px) {
                        grid-template-columns: 1fr;
                        grid-template-rows: auto 8rem;
                        grid-template-areas: 
                            "content"
                            "right";
                    }

                    // Para Telas muito pequenas (smartphones, < 600px)
                    @media (max-width: 600px) {
                        grid-template-columns: 1fr;
                        grid-template-rows: auto;
                        grid-template-areas: 
                            "content"
                            "right";
                    }
                }

                &-form-grid-2-col-left {                     
                    display: grid;
                    grid-template-rows: 1fr;
                    grid-template-columns: 25rem auto;
                    grid-template-areas: "left content";
                    height: 100%;

                    /* Telas grandes (desktops, 960px a 1279px) */
                    @media (min-width: 960px) and (max-width: 1279px) {
                        grid-template-columns: 25rem auto;
                    }

                    /* Telas extra grandes (desktops grandes, > 1440px) */
                    @media (min-width: 1441px) {
                        grid-template-columns: 25rem auto;
                    }

                    // Para Telas médias e pequenas (tablets, smartphones, < 960px)
                    @media (max-width: 960px) {
                        grid-template-columns: 1fr;
                        grid-template-rows: 8rem auto;
                        grid-template-areas: 
                            "left"
                            "content";
                    }

                    // Para Telas muito pequenas (smartphones, < 600px)
                    @media (max-width: 600px) {
                        grid-template-columns: 1fr;
                        grid-template-rows: auto;
                        grid-template-areas: 
                            "left"
                            "content";
                    }
                }

                &-form-grid-1-col {
                    display: grid;
                    grid-template-rows: 1fr;
                    grid-template-columns: 1fr;
                    grid-template-areas: "content";
                    height: 100%;      
                    margin-left: -0.5rem;
                    margin-right: -1rem;
                }

                &-left {
                    grid-area: left;
                    background-color: transparent; // #F00;
                    //border-left: 1.5px solid #D8D8D8;
                    padding: 0.5rem;
                    height: 100%; 
                }

                &-content {
                    grid-area: content;
                    background-color: transparent; // #0F0;
                    //border-left: 1.5px solid #D8D8D8;
                    //border-right: 1.5px solid #D8D8D8;
                    height: 100%;
                    width: 100%; 
                    padding: 0.5rem;
                    //white-space: nowrap;
                    overflow: auto;
                    //text-overflow: ellipsis;
                }

                &-right {
                    grid-area: right;
                    background-color: transparent; // #00F;
                    //border-right: 1.5px solid #D8D8D8;
                    padding: 0.5rem;
                    height: 100%; 
                }
            }

            &-container {     
                height: 100%;           
                width: 100%;
                max-width: 98%;
                margin: 0 auto;

                @media (min-width: 991.1px) {
                    max-width: 98%;
                    margin: 0 auto;
                }

                @media (min-width: 1200.1px) {
                    max-width: 98%;
                    margin: 0 auto;
                }
            }

            &-footer {
                grid-area: footer;
                background: #0c356a;
            }
        }
    }
}