.cls {
    &-ui-primeng {
        &-field {
            .p-calendar {
                .p-icon-wrapper {
                    margin-top: 0.1rem;
                    margin-right: 0.7rem;
                }
            }

            .p-messages {
                white-space: pre-line !important; // Quebra de linha no texto.
            }

            /* Para navegadores WebKit (Chrome, Safari, Opera) */
            input::placeholder {
                color: #d3d3d3 !important;
                /* Altere para a cor desejada */
            }

            /* Para Firefox */
            input::-moz-placeholder {
                color: #d3d3d3 !important;
                /* Altere para a cor desejada */
            }

            /* Para Internet Explorer 10+ */
            input:-ms-input-placeholder {
                color: #d3d3d3 !important;
                /* Altere para a cor desejada */
            }

            /* Para navegadores mais antigos do Firefox */
            input:-moz-placeholder {
                color: #d3d3d3 !important;
                /* Altere para a cor desejada */
            }

            .p-inputtext {
                padding: 0.3rem 0.7rem;
                font-size: 1.3rem;
                line-height: 1.3;
                font-weight: 500;
            }

            .p-dropdown {
                width: 100%;
            }

            &-label {
                display: block;
            }

            &-wrong {
                color: #f87171 !important;
                margin-bottom: 0;

                small {
                    font-size: 1.1rem;
                }
            }

            &-check-box {
                &-frame {
                    display: flex;

                    .p-checkbox {
                        margin-right: 0.6rem;
                    }

                    .p-checkbox .p-checkbox-box {
                        height: 1.5rem;
                        width: 1.5rem;
                    }

                    .p-checkbox .p-checkbox-box .p-icon {
                        width: 1.5rem;
                        height: 1rem;
                    }

                    label {
                        font-size: 1.3rem;
                        margin-top: 0.3rem;
                        color: #000 !important;
                    }
                }
            }

            &-input {
                &-text {
                    display: block;

                    input {
                        width: 100%;
                    }

                    &-icon-spin {
                        .p-input-icon {
                            margin-top: -0.5rem !important;
                        }
                    }

                    p-iconField {
                        width: 100%;
                    }

                    .p-input-icon {
                        margin-top: -0.8rem;
                    }

                    &-area {
                        textarea {
                            width: 100%;
                            margin-bottom: -5px
                        }
                    }

                    &-button {
                        & .p-inputtext {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        & .p-button {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            line-height: 1.3;
                            border: 1px solid #cbd5e1;
                            border-left: none;
                        }
                    }
                }

                &-dropdown {
                    &-icon {}
                }

                &-password {
                    display: block;

                    .p-password {
                        width: 100%;
                    }

                    input {
                        width: 100%;
                    }
                }

                &-time {
                    display: block;

                    .p-inputtext {
                        width: 100%;
                    }
                }

                &-group {
                    display: flex;
                    width: 100%;



                    &-show {
                        display: flex;

                        &-left {
                            input {
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                            }

                            .p-inputgroup-addon {
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;

                                border: 3px solid #cbd5e1;
                                border-right: none;

                                span {
                                    font-family: 'Open Sans', sans-serif;
                                    letter-spacing: 0.2rem;
                                }
                            }
                        }

                        &-right {
                            input {
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                            }

                            .p-inputgroup-addon {
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;

                                border: 3px solid #cbd5e1;
                                border-left: none;

                                span {
                                    font-family: 'Open Sans', sans-serif;
                                    letter-spacing: 0.2rem;
                                }
                            }
                        }
                    }
                }

                &-phone-data {
                    &-frame {
                        display: flex;
                        justify-content: space-between;
                        border: 1px solid #cbd5e1;
                        border-radius: 6px;

                        height: 100%;

                        &:hover {
                            border-color: #94a3b8;
                        }
                    }

                    .p-inputtext.ng-dirty.ng-invalid {
                        border: 1px solid #f87171;
                    }

                    &-ddi {
                        width: 22%;
                        min-width: 9rem;

                        .p-inputtext {
                            width: 100%;
                        }

                        .p-dropdown {
                            border-top: none;
                            border-bottom: none;
                            border-left: none;
                            border-radius: 0;
                            border-top-left-radius: 6px;
                            border-bottom-left-radius: 6px;
                        }
                    }

                    &-ddd {
                        width: 22%;

                        input {
                            width: 100%;
                            border-radius: 0;
                            border-top: none;
                            border-bottom: none;
                        }
                    }

                    &-number {
                        width: 54%;

                        input {
                            width: 100%;
                            border-radius: 0;

                            border-top: none;
                            border-bottom: none;
                            border-right: none;

                            border-top-right-radius: 6px;
                            border-bottom-right-radius: 6px;
                        }
                    }
                }

                &-dropdown-text {
                    &-frame {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        &-input {
                            width: 60%;

                            .p-inputtext {
                                width: 100%;
                                border-right: none;
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                            }
                        }

                        &-dropdown {
                            width: 40%;

                            .p-dropdown {
                                width: 100%;
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                            }
                        }
                    }
                }
            }

            &-show-input {
                div {
                    background: #e1e1e1;
                    color: #000;
                    font-weight: 500;
                    width: 100%;
                    font-size: 1.3rem;
                    border-radius: 6px;
                    padding: 0 0.75rem;
                    border: 1px solid #cbd5e1;
                    height: 2.4rem;
                }

            }

            &-tooltip {
                &-basic {

                    padding: 0.2rem;
                    width: auto !important;


                    i {
                        color: #ff0;
                    }

                    .p-tooltip-text {
                        background-color: #0C356A;
                        font-size: 1rem;
                        width: 25rem;
                        border: 2px solid #ff0;

                        // preciso que ele quebre a linha
                        white-space: pre-line !important;
                    }
                }
            }
        }
    }

    /* Telas pequenas (smartphones, < 600px) - específico smartphones*/
    @media (max-width: 599px) {}

    /* Telas médias (tablets, 600px a 959px) - específicos tablets */
    @media (min-width: 600px) and (max-width: 959px) {}

    /* Telas grandes (desktops, 960px a 1279px) - específicos desktops*/
    @media (min-width: 960px) and (max-width: 1279px) {}

    /* Telas extra grandes (monitores de notebook, 1280px a 1440px) - específicos monitores de notebook */
    @media (min-width: 1280px) and (max-width: 1440px) {}

    /* Telas extra grandes (desktops grandes, > 1440px) - específicos desktops grandes*/
    @media (min-width: 1441px) {}
}