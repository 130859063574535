// Os nomes das variáveis devem ser compostas na seguinte ordem, quando existir as informações: $component-state-property-size


/* Barlow Condensed */
@font-face {
  font-family: "Barlow Condensed";
  src: url("../../../../archives/fonts/Barlow_Condensed/BarlowCondensed-Regular.ttf");
  src: local('Open Sans'), url("../../../../archives/fonts/Barlow_Condensed/BarlowCondensed-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Tajawal */
@font-face {
  font-family: "Tajawal";
  src: url("../../../../archives/fonts/Tajawal/Tajawal-Medium.ttf");
  src: local('Open Sans'), url("../../../../archives/fonts/Tajawal/Tajawal-Medium.ttf") format("truetype");
  font-weight: 400;
}

/* Sofia Sans Extra Condensed */
@font-face {
  font-family: "Sofia Sans Extra Condensed";
  src: url("../../../../archives/fonts/Sofia_Sans_Extra_Condensed/static/SofiaSansExtraCondensed-Regular.ttf");
  src: local('Open Sans'), url("../../../../archives/fonts/Sofia_Sans_Extra_Condensed/static/SofiaSansExtraCondensed-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Sofia Sans Condensed */
@font-face {
  font-family: "Sofia Sans Condensed";
  src: url("../../../../archives/fonts/Sofia_Sans_Condensed/static/SofiaSansCondensed-Regular.ttf");
  src: local('Open Sans'), url("../../../../archives/fonts/Sofia_Sans_Condensed/static/SofiaSansCondensed-Regular.ttf") format("truetype");
  font-weight: 400;
}


/* Signika */
@font-face {
  font-family: "Signika";
  src: url("../../../../archives/fonts/Signika/static/Signika-Regular.ttf");
  src: local('Open Sans'), url("../../../../archives/fonts/Signika/static/Signika-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Fira Sans Condensed */
@font-face {
  font-family: "Fira Sans Condensed";
  src: url("../../../../archives/fonts/Fira_Sans_Condensed/FiraSansCondensed-Regular.ttf");
  src: local('Open Sans'), url("../../../../archives/fonts/Fira_Sans_Condensed/FiraSansCondensed-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Roboto Condensed */
@font-face {
  font-family: "Roboto Condensed";
  src: url("../../../../archives/fonts/Roboto_Condensed/static/RobotoCondensed-Regular.ttf");
  src: local('Open Sans'), url("../../../../archives/fonts/Roboto_Condensed/static/RobotoCondensed-Black.ttf") format("truetype");
  font-weight: 400;
}

/* Smooch Sans */
@font-face {
  font-family: "Smooch Sans";
  src: url("../../../../archives/fonts/Smooch_Sans/static/SmoochSans-Regular.ttf");
  src: local('Open Sans'), url("../../../../archives/fonts/Smooch_Sans/static/SmoochSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Open Sans */
@font-face {
  font-family: "Open Sans";
  src: url("../../../../archives/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf");
  src: local('Open Sans'), url("../../../../archives/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Open Sans Condensed*/
@font-face {
  font-family: "Open Sans Condensed";
  src: url("../../../../archives/fonts/Open_Sans/static/OpenSans_Condensed/OpenSans_Condensed-Medium.ttf");
  src: local('Open Sans'), url("../../../../archives/fonts/Open_Sans/static/OpenSans_Condensed/OpenSans_Condensed-Medium.ttf") format("truetype");
  font-weight: 400;
}

/* Concert One */
@font-face {
  font-family: "Concert One";
  src: url("../../../../archives/fonts/Concert_One/ConcertOne-Regular.ttf");
  src: local('Concert One'), url("../../../../archives/fonts/Concert_One/ConcertOne-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Racing Sans One */
@font-face {
  font-family: "Racing Sans One";
  src: url("../../../../archives/fonts/Racing_Sans_One/RacingSansOne-Regular.ttf");
  src: local('Racing Sans One'), url("../../../../archives/fonts/Racing_Sans_One/RacingSansOne-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Bruno Ace SC */
@font-face {
  font-family: "Bruno Ace SC";
  src: url("../../../../archives/fonts/Bruno_Ace_SC/BrunoAceSC-Regular.ttf");
  src: local('Bruno Ace SC'), url("../../../../archives/fonts/Bruno_Ace_SC/BrunoAceSC-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Chewy */
@font-face {
  font-family: "Chewy";
  src: url("../../../../archives/fonts/Chewy/Chewy-Regular.ttf");
  src: local('Chewy'), url("../../../../archives/fonts/Chewy/Chewy-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Knewave */
@font-face {
  font-family: "Knewave";
  src: url("../../../../archives/fonts/Knewave/Knewave-Regular.ttf");
  src: local('Knewave'), url("../../../../archives/fonts/Knewave/Knewave-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Poetsen One */
@font-face {
  font-family: "Poetsen One";
  src: url("../../../../archives/fonts/Poetsen_One/PoetsenOne-Regular.ttf");
  src: local('Poetsen One'), url("../../../../archives/fonts/Poetsen_One/PoetsenOne-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Nunito Sans */
@font-face {
  font-family: "Nunito Sans";
  src: url("../../../../archives/fonts/Nunito_Sans/static/NunitoSans_10pt-Regular.ttf");
  src: local('Nunito Sans'), url("../../../../archives/fonts/Nunito_Sans/static/NunitoSans_10pt-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Nunito Sans Medium";
  src: url("../../../../archives/fonts/Nunito_Sans/static/NunitoSans_10pt-Medium.ttf");
  src: local('Nunito Sans Medium'), url("../../../../archives/fonts/Nunito_Sans/static/NunitoSans_10pt-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Nunito Sans Semibold";
  src: url("../../../../archives/fonts/Nunito_Sans/static/NunitoSans_10pt-SemiBold.ttf");
  src: local('Nunito Sans Semibold'), url("../../../../archives/fonts/Nunito_Sans/static/NunitoSans_10pt-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Nunito Sans Bold";
  src: url("../../../../archives/fonts/Nunito_Sans/static/NunitoSans_10pt-Bold.ttf");
  src: local('Nunito Sans Bold'), url("../../../../archives/fonts/Nunito_Sans/static/NunitoSans_10pt-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Hanken Grotesk */
@font-face {
  font-family: "Hanken Grotesk";
  src: url("../../../../archives/fonts/Hanken_Grotesk/static/HankenGrotesk-Regular.ttf");
  src: local('Hanken Grotesk'), url("../../../../archives/fonts/Hanken_Grotesk/static/HankenGrotesk-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Hanken Grotesk Medium";
  src: url("../../../../archives/fonts/Hanken_Grotesk/static/HankenGrotesk-Medium.ttf");
  src: local('Hanken Grotesk Medium'), url("../../../../archives/fonts/Hanken_Grotesk/static/HankenGrotesk-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Hanken Grotesk Semibold";
  src: url("../../../../archives/fonts/Hanken_Grotesk/static/HankenGrotesk-SemiBold.ttf");
  src: local('Hanken Grotesk Semibold'), url("../../../../archives/fonts/Hanken_Grotesk/static/HankenGrotesk-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Hanken Grotesk Bold";
  src: url("../../../../archives/fonts/Hanken_Grotesk/static/HankenGrotesk-Bold.ttf");
  src: local('Hanken Grotesk Bold'), url("../../../../archives/fonts/Hanken_Grotesk/static/HankenGrotesk-Bold.ttf") format("truetype");
  font-weight: 700;
}


/* Aleo */
@font-face {
  font-family: "Aleo";
  src: url("../../../../archives/fonts/Aleo/static/Aleo-Regular.ttf");
  src: local('Aaleo Regular'), url("../../../../archives/fonts/Aleo/static/Aleo-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Aleo Medium";
  src: url("../../../../archives/fonts/Aleo/static/Aleo-Medium.ttf");
  src: local('Aleo Medium'), url("../../../../archives/fonts/Aleo/static/Aleo-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Aleo Semibold";
  src: url("../../../../archives/fonts/Aleo/static/Aleo-SemiBold.ttf");
  src: local('Aleo Semibold'), url("../../../../archives/fonts/Aleo/static/Aleo-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Aleo Bold";
  src: url("../../../../archives/fonts/Aleo/static/Aleo-SemiBold.ttf");
  src: local('Aleo Bold'), url("../../../../archives/fonts/Aleo/static/Aleo-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Font Dancing */
@font-face {
  font-family: "Dancing";
  src: url("../../../../archives/fonts/Dancing/static/DancingScript-Regular.ttf");
  src: local('Dancing Regular'), url("../../../../archives/fonts/Dancing/static/DancingScript-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Dancing Medium";
  src: url("../../../../archives/fonts/Dancing/static/DancingScript-Medium.ttf");
  src: local('Dancing Medium'), url("../../../../archives/fonts/Dancing/static/DancingScript-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Dancing Semibold";
  src: url("../../../../archives/fonts/Dancing/static/DancingScript-SemiBold.ttf");
  src: local('Dancing Semibold'), url("../../../../archives/fonts/Dancing/static/DancingScript-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Dancing Bold";
  src: url("../../../../archives/fonts/Dancing/static/DancingScript-SemiBold.ttf");
  src: local('Dancing Bold'), url("../../../../archives/fonts/Dancing/static/DancingScript-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Font Montserrat */
@font-face {
  font-family: "Montserrat Light";
  src: url("../../../../archives/fonts/Montserrat/static/Montserrat-Light.ttf");
  src: local('Montserrat Light'), url("../../../../archives/fonts/Montserrat/static/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../../archives/fonts/Montserrat/static/Montserrat-Regular.ttf");
  src: local('Montserrat Regular'), url("../../../../archives/fonts/Montserrat/static/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat Medium";
  src: url("../../../../archives/fonts/Montserrat/static/Montserrat-Medium.ttf");
  src: local('Montserrat Medium'), url("../../../../archives/fonts/Montserrat/static/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat Semibold";
  src: url("../../../../archives/fonts/Montserrat/static/Montserrat-SemiBold.ttf");
  src: local('Montserrat Semibold'), url("../../../../archives/fonts/Montserrat/static/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../../../../archives/fonts/Montserrat/static/Montserrat-Bold.ttf");
  src: local('Montserrat Bold'), url("../../../../archives/fonts/Montserrat/static/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Font Oswald */
@font-face {
  font-family: "Oswald Light";
  src: url("../../../../archives/fonts/Oswald/static/Oswald-Light.ttf");
  src: local('Oswald Light'), url("../../../../archives/fonts/Oswald/static/Oswald-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Oswald";
  src: url("../../../../archives/fonts/Oswald/static/Oswald-Regular.ttf");
  src: local('Oswald Regular'), url("../../../../archives/fonts/Oswald/static/Oswald-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Oswald Medium";
  src: url("../../../../archives/fonts/Oswald/static/Oswald-Medium.ttf");
  src: local('Oswald Medium'), url("../../../../archives/fonts/Oswald/static/Oswald-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Oswald Semibold";
  src: url("../../../../archives/fonts/Oswald/static/Oswald-SemiBold.ttf");
  src: local('Oswald Semibold'), url("../../../../archives/fonts/Oswald/static/Oswald-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Oswald Bold";
  src: url("../../../../archives/fonts/Oswald/static/Oswald-Bold.ttf");
  src: local('Oswald Bbold'), url("../../../../archives/fonts/Oswald/static/Oswald-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Font Poppins */
@font-face {
  font-family: "Poppins Light";
  src: url("../../../../archives/fonts/Poppins/Poppins-Light.ttf");
  src: local('Poppins Light'), url("../../../../archives/fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("../../../../archives/fonts/Poppins/Poppins-Regular.ttf");
  src: local('Poppins Regular'), url("../../../../archives//fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins Medium";
  src: url("../../../../archives/fonts/Poppins/Poppins-Medium.ttf");
  src: local('Poppins Medium'), url("../../../../archives//fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins Semibold";
  src: url("../../../../archives/fonts/Poppins/Poppins-SemiBold.ttf");
  src: local('Poppins Semibold'), url("../../../../archives/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../../../../archives/fonts/Poppins/Poppins-Bold.ttf");
  src: local('Poppins Bold'), url("../../../../archives/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Font Roboto */
@font-face {
  font-family: "Roboto Light";
  src: url("../../../../archives/fonts/Roboto/Roboto-Light.ttf");
  src: local('Roboto Light'), url("../../../../archives/fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../../archives/fonts/Roboto/Roboto-Regular.ttf");
  src: local('Roboto Regular'), url("../../../../archives/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto Medium";
  src: url("../../../../archives/fonts/Roboto/Roboto-Medium.ttf");
  src: local('Roboto Medium'), url("../../../../archives/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto Bold";
  src: url("../../../../archives/fonts/Roboto/Roboto-Bold.ttf");
  src: local('Roboto Bold'), url("../../../../archives/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Font Rubik */
@font-face {
  font-family: "Rubik Light";
  src: url("../../../../archives/fonts/Rubik/static/Rubik-Light.ttf");
  src: local('Rubik Light'), url("../../../../archives/fonts/Rubik/static/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Rubik";
  src: url("../../../../archives/fonts/Rubik/static/Rubik-Regular.ttf");
  src: local('Rubik Regular'), url("../../../../archives/fonts/Rubik/static/Rubik-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Rubik Medium";
  src: url("../../../../archives/fonts/Rubik/static/Rubik-Medium.ttf");
  src: local('Rubik Medium'), url("../../../../archives/fonts/Rubik/static/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Rubik Semibold";
  src: url("../../../../archives/fonts/Rubik/static/Rubik-SemiBold.ttf");
  src: local('Rubik Semibold'), url("../../../../archives/fonts/Rubik/static/Rubik-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Rubik Bold";
  src: url("../../../../archives/fonts/Rubik/static/Rubik-Bold.ttf");
  src: local('Rubik Bold'), url("../../../../archives/fonts/Rubik/static/Rubik-Bold.ttf") format("truetype");
  font-weight: 700;
}